// Determine the stage
const stage = window.location.hostname === 'localhost' ? 'dev' : 'prod';

/**
 * General project level configuration options
 */
export const config: any = {
  /**
   * The stage of the application
   */
  stage,
  /**
   * Google authentication details
   */
  auth: {
    /**
     * Client id for google
     */
    clientId: '1041818283387-clpq47t4brpqrggq9devsu0eivq3tvdd.apps.googleusercontent.com',
  },
  /**
   * Different APIs
   */
  api: {
    /**
     * The default API to use
     */
    default: 'backlink',
    /**
     * (New) Backlink api
     */
    backlink: stage === 'dev' ? 'http://localhost:8000/api' : 'https://backlink-api.launchthat.com/api',
    /**
     * Homebase api
     */
    homebase: stage === 'dev' ? 'http://34.239.143.199/api' : 'https://homebase-api.launchthat.com/api',
    /**
     * The legacy django backlink api
     */
    django: stage === 'dev' ? 'http://localhost:8000/api' : 'https://backlink.launchthat.com/api',
    /**
     * Special api endpoint to handle auth
     */
    auth: stage === 'dev' ? 'http://localhost:8001/auth/' : 'https://backlink-api.launchthat.com/auth/',
    /**
     * Special api endpoint to handle prospecting
     */
    prospect: stage === 'dev' ? 'http://localhost:8001/prospect/' : 'https://backlink-api.launchthat.com/prospect/',
    /**
     * The api endpoint for handling staging in backlink
     */
    staging: stage === 'dev' ? 'http://localhost:8001/staging' : 'https://backlink-api.launchthat.com/staging',
  },
  /**
   * Other details relevant to the legacy django application
   */
  django: {
    url: stage === 'dev' ? 'http://localhost:3000' : 'https://backlink.launchthat.com/client',
  },
};
