import { useState, useEffect } from 'react';
import { useAPI, useFilters, IOptions } from '.';

/**
 * Hook that manages server side rendering for tables
 * @param route - the route to query
 * @param defaultSort - the sort options to use by default
 * @param searchOnly - provide this if the endpoint expects a search Filter. Provide the name of the filter used to search
 * @param options - the normal useAPI options
 */
export const useServerSidePagination = (
  route: string,
  defaultSort: { column: string; direction: 'asc' | 'desc' },
  searchOnly?: string,
  options?: IOptions
) => {
  // ***** START FILTER TRACKING *****
  // Track page selection
  const [page, setPage] = useState(0);
  // Track rows per page selection
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // Track everything related to filters and table options
  const {
    filters,
    sort,
    filterStartDate,
    filterEndDate,
    appliedStartDate,
    appliedEndDate,
    setFilterDate,
    setAppliedDate,
    setSort,
    setFilters,
  } = useFilters(defaultSort);
  // Any additional params that you'd like dynamically attached to the query params of the request
  const [additionalQueryParams, setAdditionalQueryParams] = useState<{ [key: string]: any }>({});
  // This field is used to determine when filters cause refreshes if the noFetch option is provided
  const [noFetchEnabled, setNoFetchEnabled] = useState(options && options.noFetch);
  // ***** END FILTER TRACKING *****

  // ***** START ACTUAL DATA *****
  // Track the extracted data
  const [data, setData] = useState<any>([]);
  // Track count of results
  const [count, setCount] = useState(0);
  // ***** END ACTUAL DATA *****

  /**
   * Helper function used to generate query params from selected options
   */
  const getQueryParams = () => {
    // Create filter string to attach filter options
    let filterString = '';

    // If this particular endpoint accepts only a search filter, attach the correct search value
    if (searchOnly && filters[searchOnly]) {
      filterString = `&search=${filters[searchOnly].value}`;
    }
    // Otherwise, use all the filter options
    else {
      // Loop through filters
      for (const filter in filters) {
        if (filter) {
          // Attach the given filter param
          filterString += `&${filter}=${filters[filter].value}`;
        }
      }
    }

    // If additional params were provided, attach them
    for (const param in additionalQueryParams) {
      if (param) {
        // Attach the given filter param
        filterString += `&${param}=${additionalQueryParams[param]}`;
      }
    }

    return `${options && options.queryParams ? `${options.queryParams}&` : ''}page_size=${rowsPerPage}&page=${page + 1}${
      defaultSort ? `&ordering=${sort.direction === 'desc' ? '-' : ''}${sort.column}${filterString}` : ''
    }`;
  };

  // ***** START API REQUEST *****
  // Make the initial API request
  const { loading, data: responseData, setData: setResponseData, errors, queryAPI } = useAPI(route, {
    ...options,
    queryParams: getQueryParams(),
  });
  // ***** END API REQUEST *****

  // ***** START LIFE-CYCLE METHODS *****
  // Refresh the query whenever options change
  useEffect(() => {
    // Only refresh when filters change if a fetch has been performed
    if (!noFetchEnabled) refreshData();
  }, [filters, appliedStartDate, appliedEndDate, sort, page, rowsPerPage, additionalQueryParams]);

  // Re-extract data whenever request completes
  useEffect(() => {
    if (!loading && responseData.results) {
      // Grab actual data
      setData(responseData.results);
      // Grab result count
      setCount(responseData.count);
    }
  }, [loading, responseData]);

  /**
   * Re-query api with options
   */
  const refreshData = async () => {
    // Query the API
    const result = await queryAPI('GET', {
      apiName: 'django',
      queryParams: getQueryParams(),
    });

    // Set the data with the response
    setResponseData(result);

    // If no fetch was enabled, disable it here
    if (noFetchEnabled) {
      setNoFetchEnabled(false);
    }
  };
  // ***** END LIFE-CYCLE METHODS *****

  return {
    page,
    count,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    sort,
    setSort,
    loading,
    data,
    setData,
    errors,
    queryAPI,
    refreshData,
    filters,
    setFilters,
    filterStartDate,
    filterEndDate,
    appliedStartDate,
    appliedEndDate,
    setFilterDate,
    setAppliedDate,
    additionalQueryParams,
    setAdditionalQueryParams,
  };
};
