import React, { useState, useEffect } from 'react';
import { OperationsToolbar } from '../../../../components';
import { Button, DataTable, CustomHeadRender, DatePicker, CustomFilterList, ConfirmationModal } from 'lt-components';
import { useAPI, useFilters } from '../../../../hooks';
import { Route, RouteComponentProps, withRouter, Switch } from 'react-router-dom';
import { Filter, Format } from '../../../../utilities';
import { NotificationStore, UserStore } from '../../../../stores';
import { MdClear, MdRemoveRedEye, MdDone } from 'react-icons/md';
import { config } from '../../../../config';
import './Staging.scss';
import { LinkModal } from '../../../Links/components';

/**
 * Defines expected props for this component
 */
interface IProps {
  /**
   * the site that is currently selected by the user
   */
  // selectedSite: any;
}

/**
 * The Staging page
 */
export const Staging = (props: IProps) => {
  // Fetch data using api hook
  const { loading, data, queryAPI, refreshData } = useAPI('links', {
    apiName: 'staging',
    queryParams: `owner=${UserStore.user && UserStore.user.email}`,
  });
  // Manages the state of the filters and the table
  const {
    filters,
    filterStartDate,
    filterEndDate,
    appliedStartDate,
    appliedEndDate,
    setFilterDate,
    setAppliedDate,
    setFilters,
    sort,
    setSort,
  } = useFilters({
    column: 'created',
    direction: 'desc',
  });
  // Track formatted version of data
  const [formattedData, setFormattedData] = useState<any[]>([]);

  /**
   * Format the data for the table
   */
  useEffect(() => {
    if (Object.keys(data).length) {
      const newData = [];
      for (const link of data.Items) {
        if (link.downloaded === false && link.acceptable === 1)
          newData.push({ url: link.url, downloaded: link.downloaded, acceptable: link.acceptable });
      }
      setFormattedData(newData);
    }
  }, [data]);

  /**
   * Delete the selected item
   */
  const deleteItem = async (url: string) => {
    const result = await queryAPI('DELETE', {
      apiName: 'staging',
      route: `links/`,
      data: {
        url,
      },
    });
    const newData = [];
    for (const item of formattedData) {
      if (item.url !== url) newData.push(item);
    }
    setFormattedData(newData);
    // Check the response for errors
    if (result.error) {
      NotificationStore.addNotification('error', `There was a problem deleting ${url}`, 'Error');
      return;
    }

    // If there are no errors, show a success messages
    NotificationStore.addNotification('success', `Successfully deleted ${url}`, `Success`, 2000);
  };

  /**
   * Column options used to render table columns
   */
  const columns = [
    {
      name: 'url',
      label: 'Url',
      rerender: filters,
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  /**
   * Options for table
   */
  const options = {
    selectableRows: 'none' as 'none',
    rowsPerPageOptions: [10, 50, 100],
    rowsPerPage: 10,
    responsive: 'scrollMaxHeight',
    filter: false,
    search: false,
    viewColumns: false,
    download: true,
    downloadOptions: { filename: 'stagingLinks.csv', separator: ',' },
    onDownload: (buildHead: (columns: string[]) => string, buildBody: (data: any) => string, columns: string[], data: any) => {
      try {
        const updates: any = [];
        formattedData.forEach((item) => {
          updates.push(
            fetch(`${config.api.staging}/links/`, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                url: item.url,
                downloaded: true,
                acceptable: item.acceptable,
              }),
            })
          );
        });
        Promise.all(updates);
        refreshData();
        return buildHead(columns) + buildBody(data);
      } catch (err) {
        console.log('There was an error updating the links: ', err);
        // Cancel the download by returning false
        return false;
      }
    },
    customRowRender: (data: any, dataIndex: number, rowIndex: number) => {
      return (
        <tr className='MuiTableRow-root' key={rowIndex}>
          <td className='MuiTableCell-root operationsToolbar__wrapper'>
            <span>
              <a target='blank' href={data[0]}>
                {data[0]}
              </a>
            </span>
            <OperationsToolbar
              buttons={[
                // Button to delete imports
                {
                  onClick: () => {
                    deleteItem(data[0]);
                  },
                  icon: <MdClear />,
                  color: 'red',
                },
              ]}
            />
          </td>
        </tr>
      );
    },
  };

  // Apply sorting to table based on state
  if (sort.column) {
    const index = columns.findIndex((item) => item.name === sort.column);
    // @ts-ignore
    if (columns[index] && columns[index].options) columns[index].options.sortDirection = sort.direction;
  }

  return <DataTable loading={loading} options={options} columns={columns} data={formattedData} />;
};
