import React, { useState, useEffect } from 'react';
import { OperationsToolbar, RowStatusBar } from '../../../../components';
import {
  Button,
  DataTable,
  CustomHeadRender,
  Loading,
  ConfirmationModal,
  CustomFilterList,
  Breadcrumbs,
  ProgressBar,
  Card,
  Checkbox,
} from 'lt-components';
import { useAPI, useServerSidePagination } from '../../../../hooks';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { Filter, Format } from '../../../../utilities';
import { LinkModal } from '../../../Links/components';
import { MdAdd, MdModeEdit, MdClear, MdCheck, MdDelete } from 'react-icons/md';
import './ImportDrillDown.scss';
import { NotificationStore } from '../../../../stores';

/**
 * Defines expected props for this component
 */
interface IProps extends RouteComponentProps<any> {
  /**
   * the site that is currently selected by the user
   */
  selectedSite: any;
  /**
   * function to navigate back to normal imports page
   */
  navigateBack: (...args: any[]) => any;
}

/**
 * The import page
 */
const ImportDrillDownBase = (props: IProps) => {
  // Extract the id for the spreadsheet we're looking at
  const id = props.match.params.id;

  // Track modal open state
  const [deleteOpen, setDeleteOpen] = useState(false);

  // Track filters applied to URL when navigating back from adding link
  const [windowHash, setWindowHash] = useState(window.location.hash);

  // ***** START API REQUESTS *****
  // Fetch import data using api hook
  const { loading, data, queryAPI } = useAPI(`link-imports`, {
    apiName: 'django',
    id,
  });
  // Fetch the state of the import before checking the links
  const { loading: stateLoading, data: stateData, refreshData: refreshState } = useAPI(`link-imports/${id}/state`, {
    apiName: 'django',
  });
  // Fetch links associated with import
  const {
    loading: linksLoading,
    data: linksData,
    setData: setLinksData,
    refreshData: refreshLinks,
    filters,
    setFilters,
    page,
    count,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    sort,
    setSort,
    additionalQueryParams,
    setAdditionalQueryParams,
  } = useServerSidePagination(
    'link-import-entries',
    {
      column: 'url',
      direction: 'asc',
    },
    'url',
    {
      apiName: 'django',
      queryParams: `link_import=${id}`,
      noFetch: true,
    }
  );
  // Declare another API that we can use for requests that don't affect the UI
  const { queryAPI: queryHiddenAPI } = useAPI('link-import-entries/', {
    apiName: 'django',
    noFetch: true,
  });
  // ***** END API REQUESTS *****

  // ***** START STATE CHECK *****
  // Track whether the initial load is done
  const [initialLoad, setInitialLoad] = useState(true);
  // Track import progress
  const [progress, setProgress] = useState<number>(0);

  /**
   * Check the import state and display progress (if currently importing)
   */
  useEffect(() => {
    // Verify we're done loading state
    if (!stateLoading && stateData) {
      setInitialLoad(false);
      // If the state is pending, check the state again in 5 seconds
      if (stateData.state === 'pending') {
        setProgress((stateData.current / stateData.total) * 100);
        setTimeout(() => {
          refreshState();
        }, 5000);
      }
      // If the state is success, load the links
      else if (stateData.state === 'success') {
        refreshLinks();
      }
    }
  }, [stateLoading, stateData]);
  // ***** END STATE CHECK *****

  /**
   * Clear filters on table
   */
  const clearFilters = () => {
    setFilters({});
    setAdditionalQueryParams({});
  };

  /**
   * Navigate back to import drill-down page and update any links that changed
   * @param refresh - we don't use the refresh here, but LinkModal returns it for other callers
   * @param linkDetails - details about the link that was just saved
   */
  const navigateBack = async (
    refresh?: boolean,
    linkDetails?: { id: number; status: string; site: { id: number; name: string }; previousURL: string }
  ) => {
    // Navigate back to table
    props.history.push(`/${props.selectedSite.id}/imports/${id}${windowHash}`);

    // If we are provided with linkDetails, it means a link was edited and we need to update it
    if (linkDetails) {
      // We need to query the API and figure out which link entry was updated
      const linkEntries = await queryHiddenAPI('GET', {
        apiName: 'django',
        route: `link-import-entries`,
        queryParams: `link_import=${id}&search=${linkDetails.previousURL}`,
      });

      // If there is not exactly 1 result, there was a problem
      if (linkEntries.length === 1) {
        // Grab the one entry we're interested in
        const linkEntry = linkEntries[0];

        // Set new status (override it to the active_closed_approved if one of those was picked)
        let newStatus = 'recently_contacted';
        if (linkDetails.status === 'active' || linkDetails.status === 'approved' || linkDetails.status === 'closed') {
          newStatus = 'active_closed_approved';
        }

        // Update the status of the link locally
        // Find the index of the link that we updated (if it exists in the view - might have refreshed in modal view)
        const index = linksData.findIndex((link: any) => link.url === linkDetails.previousURL);
        // Update the row we're interested in (if it exists)
        if (index !== -1) {
          const updatedLinks = [...linksData];
          updatedLinks[index].status = newStatus;
          updatedLinks[index].site = linkDetails.site.name;
          updatedLinks[index].link_id = linkDetails.id;
          setLinksData(updatedLinks);
        }

        // Perform DB update of import entry
        await queryHiddenAPI('PUT', {
          apiName: 'django',
          route: `link-import-entries/${linkEntry.id}`,
          data: {
            link_id: linkDetails.id,
            site: linkDetails.site.id,
            status: newStatus,
          },
        });
      }
    }
  };

  /**
   * Navigate to the link edit modal
   * @param linkId - the id of the link we are navigating to (or 'add' if it's new)
   * @param url - the url of the link (used to pre-fill data when adding a link)
   */
  const navigateToLink = (linkId: number, url?: string) => {
    setWindowHash(window.location.hash);
    props.history.push(`/${props.selectedSite.id}/imports/${id}/links/${linkId || `add?url=${url}`}`);
  };

  /**
   * Deletes the direct import (hides it from user)
   */
  const deleteImport = async () => {
    // Make deletion
    const result = await queryAPI('DELETE', {
      apiName: 'django',
      route: `link-imports/${id}`,
    });

    // Check the response for errors
    if (result.error) {
      NotificationStore.addNotification('error', `There was a problem archiving the import`, 'Error');
      return;
    }

    // If there are no errors, show a success messages
    NotificationStore.addNotification('success', `Successfully deleted import`, `Success`, 4000);

    // Go back to the imports page and refresh the table after the deletion
    props.navigateBack(true);
  };

  /**
   * Starts the export process for the current spreadsheet
   */
  const startExport = async () => {
    const result = await queryAPI('POST', {
      apiName: 'django',
      route: `link-imports/${id}/csv`,
    });

    // Check the response for errors
    if (result.error) {
      NotificationStore.addNotification('error', `There was a problem exporting the links`, 'Error');
      return;
    }

    // If there are no errors, show a success messages
    NotificationStore.addNotification('success', `Check your email for the links`, `Export Started`, 4000);
  };

  /**
   * Column options used to render table columns
   */
  const columns = [
    {
      name: 'url',
      label: 'URL',
      rerender: filters,
      options: {
        sort: true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixed
            filter={'Search'}
          />
        ),
      },
    },
    {
      name: 'site',
      label: 'Site',
      rerender: filters,
      options: {
        sort: false,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender key={columnMeta.index} columnMeta={columnMeta} handleToggleColumn={handleToggleColumn} fixed />
        ),
      },
    },
    {
      name: 'link_id',
      label: 'Link ID',
      rerender: filters,
      options: {
        display: false,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        display: false,
      },
    },
  ];

  /**
   * Options for table
   */
  const options = {
    selectableRows: 'none' as 'none',
    rowsPerPageOptions: [10, 50, 100],
    responsive: 'scrollMaxHeight',
    filter: false,
    search: false,
    viewColumns: false,
    download: true,
    serverSide: true,
    page,
    count,
    rowsPerPage,
    // Hijacks the table download functionality
    onDownload: () => {
      startExport();
      return false;
    },
    // Replaces the toolbar with this custom toolbar
    customToolbar: () => (
      <>
        <button className='MuiIconButton-root MuiButtonBase-root MuiHoverCopy' title='Delete Import'>
          <span className='MuiIconButton-label'>
            <MdDelete className='MuiSvgIcon-root' onClick={() => setDeleteOpen(true)} />
          </span>
        </button>
        {/* If we have filters applied, show the clear button */}
        {(!Format.isObjectEmpty(filters) || additionalQueryParams.statuses) && (
          <Button className='clearFiltersButton' onClick={() => clearFilters()}>
            Clear Filters
          </Button>
        )}
        <CustomFilterList filters={filters} setFilter={setFilters} clearFilter={Filter.clearFilter} />
      </>
    ),
    // Replaces the rows of the table with these custom rows
    customRowRender: (data: any, dataIndex: number, rowIndex: number) => {
      return (
        <tr className='MuiTableRow-root' key={rowIndex}>
          <td className='MuiTableCell-root operationsToolbar__wrapper'>
            <RowStatusBar color={statusColors[data[3] as 'unknown'] as 'gray'} />
            <a className='tableLink' href={data[0]}>
              {data[0]}
            </a>
            <OperationsToolbar
              statusBarOffset
              buttons={[
                // Button to add or edit a link
                {
                  onClick: () => navigateToLink(data[2], data[0]),
                  icon: data[2] ? <MdModeEdit /> : <MdAdd />,
                  color: data[2] ? 'yellow' : 'green',
                },
              ]}
            />
          </td>
          <td className='MuiTableCell-root'>
            <span>{data[1]}</span>
          </td>
        </tr>
      );
    },
    // Runs whenever the table changes, key off of specific events to manage state
    onTableChange: (action: any, tableState: any) => {
      // Take a specific action based on change
      switch (action) {
        // Page has changed
        case 'changePage':
          // When the page changes update the page with the new one
          setPage(tableState.page);
          break;
        // Number of rows per page has changed
        case 'changeRowsPerPage':
          // When the user selects the amount of rows per page update the rows per page
          setRowsPerPage(tableState.rowsPerPage);
          break;
        // Column has been sorted
        case 'sort':
          // Loop through columns and determine which one is sorted
          for (let i = 0; i < tableState.columns.length; i++) {
            if (tableState.columns[i].sortDirection) {
              const column = columns[i].name;
              const direction = tableState.columns[i].sortDirection;
              setSort({ column, direction });
              setPage(0);
              break;
            }
          }
          break;
      }
    },
  };

  // Apply sorting to table based on state
  if (sort.column) {
    const index = columns.findIndex((item) => item.name === sort.column);
    // @ts-ignore
    if (columns[index] && columns[index].options) columns[index].options.sortDirection = sort.direction;
  }

  return (
    <div className='full-container'>
      <div className='row m-0 align-center'>
        {/* Breadcrumb area */}
        <div className='breadcrumbs__wrapper'>
          {/* When we're done loading, render the breadcrumbs */}
          {!loading && (
            <>
              {// Render an error if there was a problem getting the details using the URL id
              !data.name ? (
                // Render error if it exists
                <span className='breadcrumbs__error'>{'Bad ID provided'}</span>
              ) : (
                // Otherwise, render normal breadcrumbs
                <Breadcrumbs
                  pages={
                    loading
                      ? []
                      : [
                          {
                            text: 'Imports',
                            path: `/${props.selectedSite.id}/imports`,
                          },
                          {
                            text: data.name,
                          },
                        ]
                  }
                />
              )}
            </>
          )}
        </div>
        {/* When we have a success status, render the filter bad links option as well */}
        {stateData && stateData.state === 'success' && !data.is_direct && (
          <div className='importDrillDown__statusesContainer'>
            <Checkbox
              className='importDrillDown__statusesCheckbox'
              value={'fake'}
              checked={!!additionalQueryParams.statuses}
              onChange={() =>
                additionalQueryParams.statuses
                  ? setAdditionalQueryParams({})
                  : setAdditionalQueryParams({ statuses: 'never_contacted,ready_to_contact,unknown' })
              }
            />
            Only show contactable websites?
          </div>
        )}
      </div>

      {/* Show a loading icon if everything is loading */}
      {initialLoad && <Loading />}
      {/* Show a progress bar if the import is pending */}
      {!initialLoad && stateData.state === 'pending' && (
        <div className='importDrillDown__centerWrapper'>
          <div className='importDrillDown__centerContent'>
            <h2>Importing...</h2>
            <ProgressBar percent={progress} />
          </div>
        </div>
      )}
      {/* Show the regular content once the import completes */}
      {!initialLoad && stateData.state === 'success' && (
        <>
          {/* If this was a direct import, just show a card */}
          {data.is_direct && (
            <div className='importDrillDown__centerWrapper'>
              <Card className={{ container: 'importDrillDown__card__container', content: 'importDrillDown__card__content' }}>
                <MdCheck className='importDrillDown__card__goodIcon' />
                <p>
                  <strong>Your import is complete</strong>
                </p>
                <Button className='importDrillDown__deleteButton' onClick={() => setDeleteOpen(true)}>
                  Delete Import
                </Button>
              </Card>
            </div>
          )}
          {/* If this was a normal import, show a table with links */}
          {!data.is_direct && <DataTable loading={linksLoading} options={options} columns={columns} data={linksData} />}
        </>
      )}
      {/* Show the table once the import completes */}
      {!initialLoad && stateData.state === 'error' && (
        <div className='importDrillDown__centerWrapper'>
          <Card className={{ container: 'importDrillDown__card__container', content: 'importDrillDown__card__content' }}>
            <MdClear className='importDrillDown__card__badIcon' />
            <p>
              <strong>There was an error importing this sheet</strong>
            </p>
            <p>Please contact MARS using the link at the bottom of this page</p>
          </Card>
        </div>
      )}

      {/* Modal that's shown for deletions */}
      <ConfirmationModal
        loading={loading}
        open={deleteOpen}
        message={`Delete this import?`}
        onClose={() => setDeleteOpen(false)}
        execute={() => deleteImport()}
      />

      {/* Modal route (import spreadsheet) */}
      {/* TODO: FIGURE OUT WHY THE WORD "LINKS" and "LINK" BREAKS THIS ROUTE */}
      <Route
        path={`/${props.selectedSite.id}/imports/${id}/links/:id`}
        render={() => {
          return <LinkModal selectedSite={props.selectedSite} navigateBack={navigateBack} />;
        }}
      />
    </div>
  );
};

// Attach navigation to page
/**
 * The import page
 */
export const ImportDrillDown = withRouter(ImportDrillDownBase);

/**
 * Helpful status colors for the table
 */
const statusColors = {
  never_contacted: 'green',
  ready_to_contact: 'blue',
  recently_contacted: 'yellow',
  active_closed_approved: 'red',
  unknown: 'gray',
};
