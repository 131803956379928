import React from 'react';
import { useAPI } from '../../../../../../hooks';
import { Format } from '../../../../../../utilities';
import { Loading } from 'lt-components';
import './HistoryTab.scss';

/**
 * Defines expected props for this component
 */
interface IProps {
  /**
   * the id of the link to display notes for
   */
  linkId: string;
}

/**
 * Defines properties that should be on each history item
 */
interface IHistoryItem {
  /**
   * The date the history item was created
   */
  created: string;
  /**
   * The username of the user associated with the change
   */
  username: string;
  /**
   * The status of the history change
   */
  status: string;
}

/**
 * Generate the rows of the history table
 */
const generateRows = (history: IHistoryItem[]) => {
  return history.map((row: IHistoryItem, index: number) => {
    return (
      <div className='row m-0' key={index}>
        <div className='column'>{Format.formatDate(new Date(row.created))}</div>
        <div className='column'>{row.username}</div>
        <div className='column'>{row.status}</div>
      </div>
    );
  });
};

/**
 * The history tab of the links modal
 */
export const HistoryTab = ({ linkId }: IProps) => {
  const { loading, data: history } = useAPI(`links/${linkId}/history`, {
    apiName: 'django',
    emptyObj: [],
    noFetch: linkId === 'add',
  });

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <div className='historyTab__table'>
            <div className='historyTab__head row m-0'>
              <div className='column'>Date</div>
              <div className='column'>User</div>
              <div className='column'>Status</div>
            </div>
            <div className='historyTab__body'>{generateRows(history)}</div>
          </div>
        </>
      )}
    </>
  );
};
