import React, { Fragment, useState, useEffect, useContext } from 'react';
import { DataModal, Input, Button, FileDrop, Label, Toggle, Select } from 'lt-components';
import { useAPI, useModalRoute } from '../../../../hooks';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Validate } from '../../../../utilities';
import { NotificationStore, UserContext } from '../../../../stores';
import ReactTooltip from 'react-tooltip';
// tslint:disable-next-line: no-submodule-imports
import { MdInfoOutline } from 'react-icons/md';
import parseCSV from 'csv-parse';
import './ImportModal.scss';

/**
 * Defines expected props for this component
 */
interface IProps {
  /**
   * className applied to component
   */
  className?: string;
  /**
   * function to run when modal is being closed to navigate back to the page
   */
  navigateBack: (...args: any[]) => void;
}

/**
 * The modal for importing sheets
 */
const ImportModalBase = (props: IProps & RouteComponentProps<any>) => {
  // Track import options
  const [importOptions, setImportOptions] = useState<{ [key: string]: any }>({
    name: '',
    assign: false,
    site: '',
    affinity: '',
    status: 'pending',
    owners: [],
  });
  // Track uploaded file
  const [fileOptions, setFileOptions] = useState<{ [key: string]: any }>({
    file: undefined,
    columns: {},
    success: false,
    error: false,
  });
  // State to manage modal open state
  const [open, closeModal] = useModalRoute(props.navigateBack);
  // Track active tab
  const [activeTab, setActiveTab] = useState(0);
  // Track whether we are performing the import
  const [importing, setImporting] = useState(false);

  // ***** START PERMISSIONS *****
  // Initialize our user store to manage authentication
  const userStore = useContext(UserContext);
  // Track which sites the user has site manager status for
  const [siteManagerRoles, setSiteManagerRoles] = useState<string[]>([]);

  // When the page loads, determine which sites the user has site manager permissions for
  useEffect(() => {
    const roles = (userStore.user && userStore.user.backlink.siteRoles) || [];
    const managerRoles = [];

    for (const role of roles) {
      if ((userStore.user && userStore.user.backlink.is_superuser) || role.substring(0, 15) === 'Site Manager - ') {
        managerRoles.push(role.substring(15));
      }
    }
    setSiteManagerRoles(managerRoles);
  }, []);
  // ***** END PERMISSIONS *****

  // ***** START QUERY APIS *****
  // Set up api to actually import data
  const { queryAPI } = useAPI('link-imports', {
    apiName: 'django',
    noFetch: true,
  });
  // Retrieve a list of sites from the django backlink api
  const { loading: sitesLoading, data: sitesData } = useAPI('sites', {
    apiName: 'django',
  });
  // Retrieve a list of link statuses from the django backlink api
  const { loading: statusesLoading, data: statusesData } = useAPI('link-statuses', {
    apiName: 'django',
  });
  // Retrieve a list of users from the django backlink api
  const { loading: usersLoading, data: usersData } = useAPI('users', {
    apiName: 'django',
    queryParams: 'fields=id,username',
  });
  // Retrieve a list of affinities from the django backlink api
  const { loading: affinitiesLoading, data: affinitiesData, refreshData: refreshAffinitiesData } = useAPI('affinities', {
    apiName: 'django',
    queryParams: `active_site=${importOptions.site}&fields=id,name`,
    noFetch: true,
  });
  // ***** END QUERY APIS *****

  // ***** START SELECT OPTIONS ****
  // Turn all the API data into select options
  const [siteOptions, setSiteOptions] = useState<{ value: string; label: string }[]>([]);
  const [statusOptions, setStatusOptions] = useState<{ value: string; label: string }[]>([]);
  const [userOptions, setUserOptions] = useState<{ value: string; label: string }[]>([]);
  const [affinityOptions, setAffinityOptions] = useState<{ value: string; label: string }[]>([]);
  // Create site options
  useEffect(() => {
    // Once we finish loading
    if (!sitesLoading && sitesData.length && siteManagerRoles.length) {
      const options: any[] = [];

      // Loop and generate options
      for (const site of sitesData) {
        // If the user is a site manager for this site, add it to the list of options
        if (siteManagerRoles.includes(site.name)) {
          options.push({
            value: site.id,
            label: site.name,
          });
        }
      }

      // Save options
      setSiteOptions(options);
    }
  }, [sitesLoading, siteManagerRoles]);
  // Create status options
  useEffect(() => {
    // Once we finish loading
    if (!statusesLoading && statusesData.length) {
      const options: any[] = [];

      // Loop and generate options
      for (const status of statusesData) {
        options.push({
          value: status.value,
          label: status.name,
        });
      }

      // Save options
      setStatusOptions(options);
    }
  }, [statusesLoading]);
  // Create user options
  useEffect(() => {
    // Once we finish loading
    if (!usersLoading && usersData.length) {
      const options: any[] = [];

      // Loop and generate options
      for (const user of usersData) {
        options.push({
          value: user.id,
          label: user.username,
        });
      }

      // Save options
      setUserOptions(options);
    }
  }, [usersLoading]);
  // Create affinity options
  useEffect(() => {
    // Once we finish loading
    if (!affinitiesLoading && affinitiesData.length) {
      const options: any[] = [];

      // Loop and generate options
      for (const affinity of affinitiesData) {
        options.push({
          value: affinity.id,
          label: affinity.name,
        });
      }

      // Save options
      setAffinityOptions(options);
    }
  }, [affinitiesLoading]);
  // ***** END SELECT OPTIONS *****

  // If the selected site changes, we need to refresh the affinities
  useEffect(() => {
    if (importOptions.site) {
      refreshAffinitiesData();
      setImportOptions({ ...importOptions, affinity: '' });
    }
  }, [importOptions.site]);

  // Set header based on operation being performed
  const headerText = 'Import a New Spreadsheet';

  /**
   * Validate and then query the api using the new data
   */
  const query = async () => {
    // Only allow user to import if they are not currently importing
    if (!importing) {
      console.log('Starting import');
      setImporting(true);
      let result: { [key: string]: any } = {};

      // We need to transform the react data into a form submission to upload the file
      const data = new FormData();
      // Attach global data
      data.append('csv_import', fileOptions.file);
      data.append('name', importOptions.name);
      data.append('user', (userStore.user && userStore.user.backlink.id.toString()) || '');

      // If we are not assigning links, send basic options
      if (!importOptions.assign) {
        console.log('Performing normal import');
        // Attach any extra fields used for normal imports
        data.append('is_direct', 'false');

        // Send import to API
        result = await queryAPI('POST', {
          apiName: 'django',
          contentType: 'multipart/form-data',
          data,
        });
      }
      // Otherwise, set additional options and assign the links
      else {
        console.log('Performing assign import');
        // Attach any extra fields used for assign imports
        data.append('is_direct', 'true');
        data.append('link_status', importOptions.status);
        data.append('site', importOptions.site);
        data.append('affinity', importOptions.affinity);
        // Attach all owners
        for (const owner of importOptions.owners) {
          data.append('owners', owner);
        }

        // Send import to API
        result = await queryAPI('POST', {
          apiName: 'django',
          contentType: 'multipart/form-data',
          data,
        });
      }

      // If there were no errors, show a success message and navigate back
      if (!result.error) {
        NotificationStore.addNotification('success', `Import process has started`, `Success`, 5000);
        props.navigateBack(true, result.id);
      }

      // Done importing
      setImporting(false);
    }
  };

  /**
   * Handle CSV file uploads
   * @param files - array of files uploaded
   */
  const onUpload = (files: any[]) => {
    // Handle case where bad file was uploaded
    if (files.length === 0) {
      NotificationStore.addNotification('error', 'Bad file type', 'Error uploading', 5000);
      setFileOptions({
        file: undefined,
        columns: [],
        success: false,
        error: true,
      });
    }
    // Handle case where too many files were uploaded
    else if (files.length > 1) {
      NotificationStore.addNotification('error', 'Too many files', 'Error uploading', 5000);
      setFileOptions({
        file: undefined,
        columns: [],
        success: false,
        error: true,
      });
    }
    // Handle successful upload
    else {
      console.log(files[0]);

      // Initialize a reader and handler to parse the CSV
      const reader = new FileReader();
      reader.onload = () => {
        console.log(reader.result);
        parseCSV(reader.result, (err: any, data: any) => {
          try {
            console.log('Parsing CSV', data);

            // If the parser threw an error, pass it on
            if (err) throw new Error(err.message);
            // Otherwise, verify data was provided
            if (data.length <= 1) throw new Error('No data included in CSV');

            // Verify which columns were provided by looping through them
            const columns: { [key: string]: boolean } = {};
            for (const header of data[0]) {
              columns[header.toLowerCase()] = true;
            }

            // If the URL column was not provided, throw an error (it's required)
            if (!columns.url) throw new Error('Missing URL column');

            // Store the file and the column details
            setFileOptions({
              file: files[0],
              columns,
              success: true,
              error: false,
            });

            // Notify about success
            NotificationStore.addNotification('success', files[0].name, 'File Uploaded', 5000);
          } catch (err) {
            // Catch any parse errors and notify the user
            console.log(err);
            setFileOptions({
              file: undefined,
              columns: [],
              success: false,
              error: true,
            });
            NotificationStore.addNotification('error', err.message, 'Error uploading');
          }
        });
      };

      // Start the reader
      reader.readAsBinaryString(files[0]);
    }
  };

  /**
   * Verifies that all necessary fields are filled out
   */
  const checkDisabled = () => {
    // If the basic options aren't completed, return disabled
    if (!fileOptions.file || !importOptions.name) return true;
    // If they are also directly assigning links, check additional options
    if (importOptions.assign) {
      // Verify other options are provided
      if (!fileOptions.columns.site && !importOptions.site) return true;
      if (!fileOptions.columns.affinity && !importOptions.affinity) return true;
      if (!fileOptions.columns.owner && !importOptions.owners.length) return true;
      if (!importOptions.status) return true;
    }

    // The user has completed all fields
    return false;
  };

  /**
   * Import tab
   */
  const importTab = () => {
    return (
      <Fragment>
        <div className='row'>
          <div className='column input__container'>
            <div className='importModal__infoContainer flex'>
              <div className='flex'>
                <Label text='Spreadsheet containing URLs' required className='importModal__inline' />
                <MdInfoOutline
                  className='importModal__inline importModal__infoIcon'
                  data-tip={`Please upload a file with the following headers (in any order): [URL, Owner (optional), Site (optional), Affinity (optional)]. The "URL" column should contain the list of URLs you'd like to import (this is the only option you should provide if you're not assigning links to someone). The "Owner" column is optional while assigning links and should contain the usernames of the linkbuilders you'd like to assign each link to. The "Site" column is optional while assigning links and should contain the name of the site you'd like each link to be imported under. The "Affinity" column is optional while assigning links and should include the name of the affinity you'd like to import each link under.`}
                />
              </div>
            </div>
            <ReactTooltip className='importModal__tooltip' place='right' effect='solid' offset={{ left: 10 }} />
            <FileDrop accept='.csv' fileTypeNames='CSV' onUpload={onUpload} maxNumber={1} success={fileOptions.success} error={fileOptions.error} />
          </div>
        </div>

        <div className='row'>
          <div className='column flex3'>
            <Input
              label='Name this Spreadsheet'
              value={importOptions.name}
              autoComplete='off'
              required
              onChange={(event) => updateField('name', event.target.value, importOptions, setImportOptions)}
            />
          </div>
          {!!((userStore.user && userStore.user.backlink.is_superuser) || siteManagerRoles.length) && (
            <div className='column'>
              <div className='input__container'>
                <Toggle
                  label={'Assign Links?'}
                  className='importModal__toggle'
                  value={importOptions.assign}
                  onClick={() => updateField('assign', !importOptions.assign, importOptions, setImportOptions)}
                />
              </div>
            </div>
          )}
        </div>

        {/* This section only appears if you are assigning links */}
        {importOptions.assign && (
          <>
            <div className='row'>
              <div className='column'>
                {fileOptions.columns && fileOptions.columns.site && (
                  <div className='input__container'>
                    <Label text='Site' required className='importModal__inline' />
                    <p>
                      <em>"Site" spreadsheet column will be used</em>
                    </p>
                  </div>
                )}
                {(!fileOptions.columns || !fileOptions.columns.site) && (
                  <Select
                    label='Site'
                    value={importOptions.site}
                    options={siteOptions}
                    required
                    onChange={(value) => updateField('site', value, importOptions, setImportOptions)}
                  />
                )}
              </div>
              <div className='column'>
                {fileOptions.columns && fileOptions.columns.affinity && (
                  <div className='input__container'>
                    <Label text='Affinity' required className='importModal__inline' />
                    <p>
                      <em>"Affinity" spreadsheet column will be used</em>
                    </p>
                  </div>
                )}
                {(!fileOptions.columns || !fileOptions.columns.site) && (
                  <Select
                    key={`key_${importOptions.affinity}`}
                    label='Affinity'
                    value={importOptions.affinity}
                    options={affinityOptions}
                    disabled={!affinityOptions.length}
                    required
                    onChange={(value) => updateField('affinity', value, importOptions, setImportOptions)}
                  />
                )}
              </div>
            </div>

            <div className='row'>
              <div className='column'>
                {fileOptions.columns && fileOptions.columns.owner && (
                  <div className='input__container'>
                    <Label text='Owners' required className='importModal__inline' />
                    <p>
                      <em>"Owner" spreadsheet column will be used</em>
                    </p>
                  </div>
                )}
                {(!fileOptions.columns || !fileOptions.columns.owner) && (
                  <Select
                    label='Owners'
                    value={importOptions.owners}
                    options={userOptions}
                    multi
                    required
                    onChange={(value) => {
                      updateField('owners', value, importOptions, setImportOptions);
                    }}
                  />
                )}
              </div>
              <div className='column'>
                <Select
                  label='Status'
                  value={importOptions.status}
                  options={statusOptions}
                  required
                  onChange={(value) => updateField('status', value, importOptions, setImportOptions)}
                />
              </div>
            </div>
          </>
        )}

        <div className='row m-0'>
          <Button disabled={checkDisabled()} className='dataModal__button' onClick={() => query()}>
            Import
          </Button>
        </div>
      </Fragment>
    );
  };

  return (
    <DataModal
      headerText={headerText}
      loading={importing}
      open={open}
      onClose={closeModal}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      className={`${props.className ? props.className : ''}`}
      tabs={[
        {
          title: 'Details',
          body: false ? undefined : importTab(),
        },
      ]}
    />
  );
};

/**
 * The modal for importing spreadsheets
 */
export const ImportModal = withRouter(ImportModalBase);

// HELPERS

/**
 * Function to update a property of the data
 * @param field - the field name to update
 * @param value - the value to update the field with
 * @param data - the original data object
 * @param setData - the function to update the data
 * @param submitted - whether the form has already been submitted once
 */
const updateField = (field: string, value: any, data: any, setData: any) => {
  setData({ ...data, [field]: value });
};
