import React, { useState, useContext, useEffect } from 'react';
import { Switch, Route, Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { Dashboard, Loading } from 'lt-components';
import { HeaderContent } from './components';
import { SignIn, Imports, Dash, Prospect } from './pages';
// tslint:disable-next-line: no-submodule-imports
import { MdSettings, MdGetApp, MdPeople, MdList, MdDescription, MdAssessment, MdLink, MdGpsFixed, MdSearch } from 'react-icons/md';
// tslint:disable-next-line: no-submodule-imports
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { UserContext, IUser } from './stores';
import { useAPI } from './hooks';
import { config } from './config';
import './styles/styles.scss';
// TODO: remove troll
import { randomNoise, snackTime, thanosSnap } from './troll/troll';
import { Hidden } from '@material-ui/core';
// randomNoise();
// thanosSnap();
snackTime();

// The material ui theme for overrides

const MUITheme = createMuiTheme({
  overrides: {
    MUIDataTableToolbar: {
      left: {
        flex: '1 1 30%',
      },
      actions: {
        flex: '1 1 70%',
      },
    },
    MUIDataTableSelectCell: {
      fixedHeader: {
        backgroundColor: 'white',
        zIndex: '101 !important',
      },
    },
    MuiToolbar: {
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      },
    },
    MUIDataTableHeadCell: {
      root: {
        fontWeight: 'bold',
        fontSize: '0.8125rem',
        whiteSpace: 'nowrap',
        minWidth: 150,
        maxWidth: 450,
        color: 'rgba(0, 0, 0, 0.87)',
      },
      filterAction: {
        right: 'auto',
        marginLeft: '35px',
      },
    },
    MuiTableSortLabel: {
      root: {
        alignSelf: 'center',
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
      },
    },
    MuiTableCell: {
      root: {
        minWidth: 150,
        maxWidth: 450,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
});

/**
 * The main app component that renders the rest of the application
 */
const AppBase = observer((props: RouteComponentProps) => {
  // When app starts, loading is true
  const [loading, setLoading] = useState(true);

  // ***** START USER TRACKING *****
  // Initialize our user store to manage authentication
  const userStore = useContext(UserContext);

  /**
   * Checks whether the user is logged in by grabbing his token from the browser
   */
  const checkUser = async () => {
    console.log('Checking user');
    const userToken = localStorage.getItem('user');

    if (userToken) {
      try {
        const user = JSON.parse(userToken);
        console.log(user);
        // If the user was parsed correctly from the token, sign in
        if (user && user) userStore.signIn(user as IUser);
      } catch (err) {
        console.log(err);
        localStorage.removeItem('user');
      }
    }

    // Finish loading
    setLoading(false);
  };

  // When component mounts
  useEffect(() => {
    // Check whether the user is logged in
    checkUser();
  }, []);
  // ***** END USER TRACKING *****

  // ***** START SITE TRACKING *****
  // Extract siteId from URL in the case of a refresh so we can re-select the site
  const siteId = Number.parseInt(window.location.pathname.split('/')[1], 10);

  // Retrieve a list of sites from the django backlink api
  const { loading: sitesLoading, data: sitesData, refreshData: refreshSitesData } = useAPI('sites', {
    apiName: 'django',
    noFetch: true,
  });
  // Track which site the user is currently viewing
  const [selectedSite, setSelectedSite] = useState<any>(undefined);
  // Track when we're done setting up the selected site data
  const [setupComplete, setSetupComplete] = useState(false);

  // Grab sites data once user loads
  useEffect(() => {
    if (userStore.user && userStore.user.backlink && !sitesLoading && !sitesData.length) {
      refreshSitesData();
    }
  }, [userStore.user]);

  // Once sites are all grabbed, attempt to set the selectedSite using the route param
  useEffect(() => {
    if (!sitesLoading && sitesData.length) {
      if (sitesData.length && siteId) {
        // Extract user's backlink permissions
        const userRoles: string[] = (userStore.user && userStore.user.backlink.siteRoles) || [];

        // Find the site matching the URL id in our sitesData array
        const matchedSite = sitesData[sitesData.findIndex((site: any) => site.id === siteId)];
        // If the matched site is in their privileges, show it (otherwise redirect to base page)
        if (matchedSite && (userRoles.includes(matchedSite.link_builder_group) || userRoles.includes(matchedSite.manager_group))) {
          setSelectedSite(matchedSite);
        }
      }
      setSetupComplete(true);
    }
  }, [sitesLoading]);
  // ***** END SITE TRACKING *****

  // ***** START AUTH REDIRECT *****
  // Track whether we are currently redirecting
  const [redirecting, setRedirecting] = useState(false);
  // Track redirect path
  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined);

  /**
   * Redirect user to sign in page if not authenticated
   */
  const handleNonAuthRedirect = () => {
    // Prevent infinite reloads by checking whether we are currently redirecting
    if (!redirecting) {
      setRedirecting(true);
      setRedirectPath(props.location.pathname);
    }
    // Return sign in route
    return <Redirect to={'/sign-in'} />;
  };

  /**
   * Redirect user to app after authentication
   */
  const handleAuthRedirect = () => {
    // If we have a redirect path set (landed on a page and were redirected to sign in), route there
    if (redirectPath) return <Redirect to={redirectPath} />;
    // Otherwise, just redirect to dashboard
    return <Redirect to={'/'} />;
  };
  // ***** END AUTH REDIRECT *****

  /**
   * These routes are used to generate the navigation for the app.
   * Make sure to insert a new object with a path and the component you'd like to render.
   * @param {string} name - the name of the route (shown in sidebar)
   * @param {string} path - the relative URL path the route is associated with
   * @param {boolean} adminOnly - an optional field that denotes whether this route is only intended for admins
   * @param {boolean} exact - whether the URL path must be an exact match to render the component (can be used
   * to render nested pages if turned off - ex. /links with exact match false would match /links and /links/2)
   * @param {() => React.ReactNode} render - function to render component within dashboard body
   * @param {React.ReactNode} icon - icon associated with route (shown in sidebar)
   * @param {string} overrideLink - override the location where the sidebar button points (can be external)
   */
  const routes = selectedSite
    ? [
        // Links page
        {
          name: 'Links',
          path: `/${selectedSite.id}/links`,
          exact: false,
          render: () => {
            return (
              <div className='container'>
                <p>Coming to a theater near you sometime in 2019...</p>
              </div>
            );
          },
          icon: <MdLink />,
          overrideLink: `${config.django.url}/index.html#/sites/${selectedSite.id}/links`,
        },
        // TODO: Eventually add todo feature
        // To Do page
        // {
        //   name: 'To Do',
        //   path: `${selectedSite.id}/todo`,
        //   exact: false,
        //   render: () => {
        //     return (
        //       <div className='container'>
        //         <p>Coming to a theater near you sometime in 2019...</p>
        //       </div>
        //     );
        //   },
        //   icon: <MdList/>,
        // },
        // Reports page
        {
          name: 'Reports',
          path: `/${selectedSite.id}/reports`,
          exact: false,
          render: () => {
            return (
              <div className='container'>
                <p>Coming to a theater near you sometime in 2019...</p>
              </div>
            );
          },
          icon: <MdAssessment />,
          overrideLink: `${config.django.url}/index.html#/sites/${selectedSite.id}/reports/spider`,
        },
        // Affinities page
        {
          name: 'Affinities',
          path: `/${selectedSite.id}/affinities`,
          exact: false,
          render: () => {
            return (
              <div className='container'>
                <p>Coming to a theater near you sometime in 2019...</p>
              </div>
            );
          },
          icon: <MdGpsFixed />,
          overrideLink: `${config.django.url}/index.html#/sites/${selectedSite.id}/affinities`,
        },
        // Imports page
        {
          name: 'Imports',
          path: `/${selectedSite.id}/imports`,
          exact: false,
          render: () => {
            return <Imports selectedSite={selectedSite} />;
          },
          icon: <MdGetApp />,
        },
        // Templates page
        {
          name: 'Templates',
          path: `/${selectedSite.id}/templates`,
          exact: false,
          render: () => {
            return (
              <div className='container'>
                <p>Coming to a theater near you sometime in 2019...</p>
              </div>
            );
          },
          icon: <MdDescription />,
          overrideLink: `${config.django.url}/index.html#/sites/${selectedSite.id}/templates`,
        },
        // Prospect page
        {
          name: 'Prospect',
          path: `/${selectedSite.id}/prospect`,
          exact: false,
          render: () => {
            return <Prospect selectedSite={selectedSite} />;
          },
          icon: <MdSearch />,
        },
        // Users page
        {
          name: 'Users',
          path: `/${selectedSite.id}/users`,
          adminOnly: true,
          exact: false,
          render: () => {
            return (
              <div className='container'>
                <p>Coming to a theater near you sometime in 2019...</p>
              </div>
            );
          },
          icon: <MdPeople />,
          overrideLink: `${config.django.url}/index.html#/sites/${selectedSite.id}/users`,
        },
      ]
    : // If no site is selected, don't render any routes
      [
        {
          name: 'Select Site',
          path: '/',
          hidden: true,
          exact: true,
          render: () => {
            return <Dash />;
          },
          icon: <MdDescription />,
        },
      ];

  // Don't render anything until we're done loading
  if (loading || (userStore.user && !setupComplete)) {
    return <Loading />;
  }
  // Once we're done loading, run some additional checks
  else {
    // Redirect the user to sign in if they are not authenticated and not already there
    if (!userStore.user && props.location.pathname !== '/sign-in') {
      return handleNonAuthRedirect();
    }
    // Redirect the user off of sign in if they are authenticated
    else if (userStore.user && props.location.pathname === '/sign-in') {
      return handleAuthRedirect();
    }
    // Otherwise, render the app normally (once done loading)
    else {
      return (
        <Switch>
          {/* Sign in page route */}
          <Route path='/sign-in' component={SignIn} />

          {/* Dashboard route (renders other routes inside Dashboard component) */}
          <Route
            path='/'
            render={() => (
              <MuiThemeProvider theme={MUITheme}>
                <Dashboard
                  defaultRoute={selectedSite ? `/${selectedSite.id}/imports` : '/'}
                  headerLogo={`${process.env.PUBLIC_URL}/images/horizontal-logo.png`}
                  routes={routes}
                  user={userStore.user}
                  signOut={() => userStore.signOut()}
                  sidebarSize={'mini'}
                  profileOptions={[
                    {
                      icon: MdSettings,
                      text: 'Account Settings',
                      action: () => window.open('https://myaccount.google.com/', '_blank'),
                    },
                    {
                      icon: MdGetApp,
                      text: 'Install LinkFox',
                      action: () => window.open('https://chrome.google.com/webstore/detail/linkfox/nijgdpmhbfmjeldjdlkokgaffmoiipfe', '_blank'),
                    },
                  ]}
                  headerComponents={
                    sitesData.length ? (
                      <HeaderContent
                        userRoles={(userStore.user && userStore.user.backlink.siteRoles) || []}
                        sites={{
                          all: sitesData,
                          selected: selectedSite,
                          changeSelected: setSelectedSite,
                        }}
                      />
                    ) : (
                      undefined
                    )
                  }
                />
              </MuiThemeProvider>
            )}
          />
        </Switch>
      );
    }
  }
});

// Attach router to add navigation functions
/**
 * The main app component that renders the rest of the application
 */
export const App = withRouter(AppBase);
