import React from 'react';
import './RowStatusBar.scss';

/**
 * Defines expected props for this component
 */
interface IProps {
  /**
   * The color of the bar
   */
  color: 'green' | 'yellow' | 'red' | 'gray';
}

/**
 * A simple colored bar attached to a table row to show the status
 */
export const RowStatusBar = ({ color }: IProps) => {
  return <div className={`rowStatusBar rowStatusBar--${color}`} />;
};
