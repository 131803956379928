/**
 * Contains methods relating to parameters
 */
export const Params = {
  /**
   * Extracts query parameters from URL and returns them as an object
   */
  getQueryParams: () => {
    // The object where we'll store our params
    const params: { [key: string]: string } = {};

    // Extract the params into pairs
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      // Store the param
      params[pair[0]] = pair[1];
    }

    // Return object
    return params;
  },
};
