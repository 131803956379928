import React from 'react';
import './Pagination.scss';

/**
 * Defines expected props for this component
 */
interface IProps {
  /**
   * className applied to component
   */
  className?: string;
  /**
   * The array of pagination
   */
  pagination: number[];
  /**
   * Update the pagination correctly
   */
  handlePaginationUpdate: (pageNumber: number) => void;
  /**
   * The current page of the pagination
   */
  currentPage: number;
  /**
   * Whether the next button should be disabled
   */
  nextDisabled?: boolean;
}

/**
 * DESCRIPTION_OF_COMPONENT
 */
export const Pagination = ({ currentPage, pagination, handlePaginationUpdate, nextDisabled }: IProps) => {
  return (
    <div className='row m-0 justify-center pagination__container'>
      {currentPage !== 1 && (
        <p onClick={() => handlePaginationUpdate(currentPage - 1)} className='pagination__link pagination__link-prev'>
          Prev
        </p>
      )}
      {pagination.length > 1 &&
        pagination.map((page: number) => {
          return (
            <p
              onClick={() => (page !== currentPage ? handlePaginationUpdate(page) : null)}
              key={page}
              className={`pagination__link ${page === currentPage ? 'pagination__link-selected' : ''}`}
            >
              {page}
            </p>
          );
        })}
      {!nextDisabled && (
        <p onClick={() => handlePaginationUpdate(currentPage + 1)} className='pagination__link pagination__link-next'>
          Next
        </p>
      )}
    </div>
  );
};
