import React from 'react';
import { DropDown } from 'lt-components';
// tslint:disable-next-line: no-submodule-imports
import { MdArrowDropDown } from 'react-icons/md';
import './HeaderContent.scss';

/**
 * Defines expected props for this component
 */
interface IProps {
  /**
   * className applied to component
   */
  className?: string;
  /**
   * the user's roles
   */
  userRoles: string[];
  /**
   * the details relating to sites
   */
  sites: {
    all: any[];
    selected: any;
    changeSelected: (...args: any[]) => void;
  };
}

/**
 * The additional components rendered in the header
 */
export const HeaderContent = (props: IProps) => {
  // Define ref for drop down
  let dropDownRef: any;
  console.log('Header Content Props: ', props);
  const generateSiteOptions = () => {
    const options = [] as { text: string; action: () => void }[];
    for (const site of props.sites.all) {
      // Only return sites the user has permission to see
      if (props.userRoles.includes(site.link_builder_group) || props.userRoles.includes(site.manager_group)) {
        options.push({
          text: site.name as string,
          action: (() => props.sites.changeSelected(site)) as () => void,
        });
      }
    }

    options.sort((a: any, b: any) => {
      if (a.text < b.text) return -1;
      else if (a.text > b.text) return 1;
      return 0;
    });
    return options;
  };

  const siteOptions = generateSiteOptions();
  console.log('Site options', siteOptions);

  return (
    <div className={`headerContent__container ${props.className ? props.className : ''}`}>
      <div className={'headerContent__content'}>
        <span className='headerContent__site' onClick={(e) => dropDownRef.toggleMenu(e)}>
          {props.sites.selected ? props.sites.selected.name : 'Select Site'}
          <MdArrowDropDown className='headerContent__arrow' />
        </span>
        <DropDown options={siteOptions} ref={(ref) => (dropDownRef = ref)} />
      </div>
    </div>
  );
};
