import React, { Fragment, useState, useEffect } from 'react';
import { DataModal, Input, Select, Button, Checkbox } from 'lt-components';
import { useAPI, useModalRoute } from '../../../../hooks';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Validate, Format, Params } from '../../../../utilities';
import { NotesTab, HistoryTab, PreviewTab } from './tabs';
import { NotificationStore, UserStore } from '../../../../stores';
import { MdAdd, MdRemove, MdEmail } from 'react-icons/md';
import './LinkModal.scss';

/**
 * Defines expected props for this component
 */
interface IProps extends RouteComponentProps<any> {
  /**
   * className applied to component
   */
  className?: string;
  /**
   * function to run when modal is being closed to navigate back to the page
   */
  navigateBack: (...args: any[]) => void;
  /**
   * the site that is currently selected by the user
   */
  selectedSite: any;
}

/**
 * The modal for link data
 */
const LinkModalBase = (props: IProps) => {
  // Extract route params to figure out which item is being modified or created
  const id = props.match.params.id;
  /**
   * Options used in API hook (defines id of item to fetch and an empty representation of the object in case we're adding it)
   */
  const options = {
    apiName: 'django',
    id,
    emptyObj: {
      url: '',
      affinity: '',
      contacts: [
        {
          is_form: false,
          name: '',
          email: '',
        },
      ],
      modified: new Date().toISOString(),
      owner: UserStore.user && UserStore.user.backlink.id,
      site: props.selectedSite.id,
      template: '',
      active_override: false,
      status: 'pending',
    },
  };

  // State to manage modal open state
  const [open, closeModal] = useModalRoute(props.navigateBack);
  // Track active tab
  const [activeTab, setActiveTab] = useState(0);
  // Track the previous status and URL of the link once we retrieve it
  const [previousStatus, setPreviousStatus] = useState('');
  const [previousURL, setPreviousURL] = useState('');
  // Track loading state for complex query scenarios (like sending emails)
  const [querying, setQuerying] = useState(false);

  // ***** START PRE-POPULATION (ADDING LINKS ONLY) *****
  // A temporary variable that lets us know when we should run initial validation (when url is pre-populated)
  const [runInitialValidation, setRunInitialValidation] = useState(false);
  // We need to pre-populate the URL if we are adding a link and it exists in query params
  useEffect(() => {
    if (id === 'add') {
      // Grab query params from URL
      const params = Params.getQueryParams();

      // If URL exist in query params, pre-populate URL
      if (params.url) {
        setData({ ...data, url: params.url });
        setPreviousURL(params.url);
        setRunInitialValidation(true);
      }
    }
  }, []);
  // If we are adding a link (and the url is pre-populated), we need to validate it
  useEffect(() => {
    if (runInitialValidation) {
      checkURL();
      setRunInitialValidation(false);
    }
  }, [runInitialValidation]);
  // ***** END PRE-POPULATION (ADDING LINKS ONLY) *****

  // ***** START QUERY APIS *****
  // Fetch link data using api hook
  const { loading, data, setData, queryAPI } = useAPI(`links`, JSON.parse(JSON.stringify(options)));
  // Retrieve a list of link statuses from the django backlink api
  const { loading: statusesLoading, data: statusesData } = useAPI('link-statuses', {
    apiName: 'django',
  });
  // Retrieve a list of sites from the django backlink api
  const { loading: sitesLoading, data: sitesData } = useAPI('sites', {
    apiName: 'django',
  });
  // Retrieve a list of users from the django backlink api
  const { loading: usersLoading, data: usersData } = useAPI('users', {
    apiName: 'django',
    queryParams: 'fields=id,username',
  });
  // Retrieve a list of affinities from the django backlink api
  const { loading: affinitiesLoading, data: affinitiesData, refreshData: refreshAffinitiesData } = useAPI('affinities', {
    apiName: 'django',
    // Normal users will query the site they're building for, super users will query the current link's site
    queryParams: `active_site=${UserStore.user && UserStore.user.backlink.is_superuser ? data.site : props.selectedSite.id}&fields=id,name`,
    // Don't fetch until we know which site is being used by the link for super users
    noFetch: UserStore.user && UserStore.user.backlink.is_superuser,
  });
  // Retrieve a list of affinities from the django backlink api
  const { loading: templatesLoading, data: templatesData, refreshData: refreshTemplatesData } = useAPI('templates', {
    apiName: 'django',
    queryParams: `affinity=${typeof data.affinity === 'object' ? data.affinity.id : data.affinity}&fields=id,title,owner`,
    noFetch: true,
  });
  // ***** END QUERY APIS *****

  // ***** START DYNAMIC QUERIES *****
  // The first executions should be avoided while data loads
  const [firstAffinityRun, setFirstAffinityRun] = useState(true);
  const [firstTemplateRun, setFirstTemplateRun] = useState(true);
  // If the selected site changes, we need to refresh the affinities
  useEffect(() => {
    // Only run this effect for super users (other users cannot change sites)
    if (UserStore.user && UserStore.user.backlink.is_superuser && data.site) {
      refreshAffinitiesData();

      // Don't clear initial values on first run (we need to keep them attached)
      if (!firstAffinityRun) {
        setData({ ...data, affinity: '', template: '' });
      }
      // Make sure to set to false after first run
      else {
        setFirstAffinityRun(false);
      }
    }
  }, [data.site]);
  // If the selected affinity changes, we need to refresh the templates
  useEffect(() => {
    // Make sure affinity is defined
    if (data.affinity) {
      refreshTemplatesData();

      // Don't clear initial values on first run (we need to keep them attached)
      if (!firstTemplateRun) {
        setData({ ...data, template: '' });
      }
      // Make sure to set to false after first run
      else {
        setFirstTemplateRun(false);
      }
    }
  }, [data.affinity]);
  // ***** END DYNAMIC QUERIES *****

  // ***** START SELECT OPTIONS *****
  // Turn all the API data into select options
  const [siteOptions, setSiteOptions] = useState<{ value: string; label: string }[]>([]);
  const [statusOptions, setStatusOptions] = useState<{ value: string; label: string }[]>([]);
  const [userOptions, setUserOptions] = useState<{ value: string; label: string }[]>([]);
  const [affinityOptions, setAffinityOptions] = useState<{ value: string; label: string }[]>([]);
  const [templateOptions, setTemplateOptions] = useState<{ value: string; label: string }[]>([]);
  // We need some state to track whether we've fixed the bad status response from Django
  const [statusFixed, setStatusFixed] = useState(false);
  // Create site options
  useEffect(() => {
    // Once we finish loading
    if (!sitesLoading && sitesData.length) {
      const options: any[] = [];

      // Loop and generate options
      for (const site of sitesData) {
        options.push({
          value: site.id,
          label: site.name,
        });
      }

      // Save options
      setSiteOptions(options);
    }
  }, [sitesLoading]);
  // Create status options
  useEffect(() => {
    // Once we finish loading
    if (!statusesLoading && statusesData.length) {
      const options: any[] = [];

      // Loop and generate options
      for (const status of statusesData) {
        options.push({
          value: status.value,
          label: status.name,
        });
      }

      // Save options
      setStatusOptions(options);
    }
  }, [statusesLoading]);
  // Create user options
  useEffect(() => {
    // Once we finish loading
    if (!usersLoading && usersData.length) {
      const options: any[] = [];

      // Loop and generate options
      for (const user of usersData) {
        options.push({
          value: user.id,
          label: user.username,
        });
      }

      // Save options
      setUserOptions(options);
    }
  }, [usersLoading]);
  // Create affinity options
  useEffect(() => {
    // Once we finish loading
    if (!affinitiesLoading && affinitiesData.length) {
      const options: any[] = [];

      // Loop and generate options
      for (const affinity of affinitiesData) {
        options.push({
          value: affinity.id,
          label: affinity.name,
        });
      }

      // Save options
      setAffinityOptions(options);
    }
  }, [affinitiesLoading]);
  // Create template options
  useEffect(() => {
    // Once we finish loading
    if (!templatesLoading && templatesData.length) {
      const options: any[] = [];

      // Loop and generate options
      for (const template of templatesData) {
        options.push({
          value: template.id,
          label: `${template.title}, Owner: ${(template.owner && template.owner.username) || 'Unknown'}`,
        });
      }

      // Save options
      setTemplateOptions(options);
    }
  }, [templatesLoading]);
  // When the link and statuses have loaded, we need to replace status with the correct option
  useEffect(() => {
    if (!statusesLoading && !loading && !statusFixed) {
      if (data && data.status) {
        // Loop and locate selected status
        for (const status of statusesData) {
          // Replace value with the actual value of the status
          if (status.name === data.status) setData({ ...data, status: status.value });
          setStatusFixed(true);
        }
      }
    }
  }, [statusesLoading, loading]);
  // Determine what the initial status and URL of the link is
  useEffect(() => {
    if (id !== 'add' && statusFixed && !previousStatus) {
      setPreviousStatus(data.status);
      setPreviousURL(data.url);
    }
  }, [statusFixed]);
  // ***** END SELECT OPTIONS *****

  // ***** START VALIDATION *****
  // Set up API to allow for querying validation APIs without affecting loading state
  const { queryAPI: queryValidationAPI } = useAPI('', {
    apiName: 'django',
    noFetch: true,
  });
  // Track whether the URL is valid
  const [urlValid, setUrlValid] = useState<boolean | undefined>(undefined);

  /**
   * Check whether the URL is valid whenever the input is blurred
   */
  const checkURL = async () => {
    // Only run it if the URL field is not empty
    if (data.url) {
      // First make sure the URL uses valid syntax
      const validSyntax = Validate.url(data.url);
      if (!validSyntax) {
        setUrlValid(false);
        return false;
      }

      // Validate URL in backend
      const result = await queryValidationAPI('GET', {
        apiName: 'django',
        route: 'links/validate_url',
        queryParams: `${id !== 'add' ? `id=${id}&` : ''}url=${data.url}`,
      });

      if (result.valid) setUrlValid(true);
      else setUrlValid(false);
    }
    // If URL is empty, set it as an error automatically
    else {
      setUrlValid(false);
    }
  };

  /**
   * Check whether the email is valid whenever the input is blurred
   * @param contact - the contact to validate
   * @param index - the index of the contact this email belongs to
   */
  const checkEmail = async (contact: { id: string; email: string }, index: number) => {
    // Only run it if the URL field is not empty
    if (contact.email) {
      // First make sure the URL uses valid syntax
      const validSyntax = Validate.email(contact.email);
      if (!validSyntax) {
        setEmailValid(index, false);
        return false;
      }
      // Validate email in backend
      const result = await queryValidationAPI('GET', {
        apiName: 'django',
        route: 'links/validate_email',
        queryParams: `${contact.id ? `id=${contact.id}&` : ''}email=${contact.email}`,
      });

      if (result.valid) setEmailValid(index, true);
      else setEmailValid(index, false);
    }
    // If email is empty, set it as an error automatically
    else {
      setEmailValid(index, false);
    }
  };

  /**
   * Set an email row's valid status
   * @param index - the index of the contact this email belongs to
   * @param valid - whether this email is valid
   */
  const setEmailValid = (index: number, valid: boolean) => {
    // Modify valid status
    const contacts = [...data.contacts];
    contacts[index].valid = valid;

    // Update data
    const newData = { ...data, contacts };
    setData(newData);
  };

  /**
   * Function to check whether the button is disabled or not
   */
  const checkDisabled = () => {
    // The button is disabled if the user is not logged in
    if (!UserStore.user) return true;

    // The button should also be disabled if...
    if (
      // the user is not a super user
      !UserStore.user.backlink.is_superuser &&
      // and the link is not owned by this person
      UserStore.user.backlink.id !== data.owner &&
      // and the previous status was active, approved, or closed
      (previousStatus === 'active' || previousStatus === 'approved' || previousStatus === 'closed')
    ) {
      return true;
    }

    // The button should also be disabled if the other values aren't all filled in
    // Url is required and must be valid
    if (!data.url || urlValid === false) return true;
    // Status is required
    if (!data.status) return true;
    // Affinity is required
    if (!data.affinity) return true;
    // Template is required
    if (!data.template) return true;
    // Owner is required
    if (!data.owner) return true;
    // Site is required
    if (!data.site) return true;

    // Loop through the contacts and make sure they all have an email or are marked as forms
    for (const contact of data.contacts) {
      // If the contact is a form, it's automatically valid
      if (contact.is_form) continue;

      // Validate emails
      // If the contact doesn't have an email, it's invalid
      if (!contact.email) return true;
      // If the contact email is not valid, it's invalid (lol)
      if (contact.valid === false) return true;
    }

    // Otherwise, the button is not disabled
    return false;
  };
  /**
   * Variable to track disabled status on each render
   */
  const disabled = checkDisabled();
  // ***** END VALIDATION *****

  // ***** START CONTACT MANAGEMENT *****
  /**
   * Returns rows for the contacts
   */
  const generateContacts = () => {
    return data.contacts.map((contact: any, index: number) => {
      return (
        <div key={index} className='row'>
          <div className='column'>
            <Input
              label='Contact Name'
              value={contact.name}
              autoComplete='off'
              onChange={(event) => {
                const newContacts = data.contacts;
                newContacts[index].name = event.target.value;
                updateField('contacts', newContacts, data, setData);
              }}
            />
          </div>
          <div className='column'>
            <Input
              label='Contact Email'
              className={{ input: `linkModal__input--${contact.valid === undefined ? 'unknown' : contact.valid ? 'success' : 'error'}` }}
              value={contact.email}
              autoComplete='off'
              disabled={contact.is_form}
              onChange={(event) => {
                const newContacts = data.contacts;
                newContacts[index].email = event.target.value;
                updateField('contacts', newContacts, data, setData);
              }}
              onBlur={() => checkEmail(contact, index)}
            />
          </div>
          <div className='inlineColumn'>
            <div className='row m-0'>
              <Checkbox
                value={''}
                checked={contact.is_form}
                onChange={() => {
                  const newContacts = data.contacts;
                  newContacts[index].is_form = !newContacts[index].is_form;

                  // Remove email if this is now marked as a form
                  if (newContacts[index].is_form) {
                    newContacts[index].email = '';
                    delete newContacts[index].valid;
                  }

                  updateField('contacts', newContacts, data, setData);
                }}
              />
              Form
            </div>
          </div>
          <div className='inlineColumn'>
            {/* Only render add button on first row */}
            {index === 0 && (
              <Button className='linkModal__contactButton linkModal__contactButton--add' onClick={() => addContact()}>
                <MdAdd className='linkModal__contactIcon' />
              </Button>
            )}
            {/* Disable remove button if we only have one contact */}
            <Button
              className='linkModal__contactButton linkModal__contactButton--remove'
              disabled={data.contacts.length <= 1}
              onClick={() => removeContact(index)}
            >
              <MdRemove className='linkModal__contactIcon' />
            </Button>
          </div>
        </div>
      );
    });
  };

  /**
   * Add a row to the contacts array
   */
  const addContact = () => {
    // Append new contact
    const contacts = [...data.contacts];
    contacts.push({
      name: '',
      email: '',
      is_form: false,
    });

    // Update data
    const newData = { ...data, contacts };
    setData(newData);
  };

  /**
   * Remove a row from the contacts array
   */
  const removeContact = (index: number) => {
    // Remove contact
    const contacts = [...data.contacts];
    contacts.splice(index, 1);

    // Update data
    const newData = { ...data, contacts };
    setData(newData);
  };

  /**
   * Extract the contact names (used to generate dynamic template)
   */
  const getContactNames = (contacts: any) => {
    // If there are no contacts, return an empty string
    if (!contacts) return '';
    // Otherwise, extract any names from the contacts

    // Track whether we found any names
    let namesExist = false;
    // Track list of names used for query
    let contactString = '';

    // Loop over each contact
    for (const contact of contacts) {
      // If we find a name, append it to our contact string
      if (contact.name) {
        namesExist = true;
        contactString += `${contactString.length ? ',' : ''}${contact.name}`;
      }
    }

    // If we didn't find any names, return an empty string
    if (!namesExist) return '';
    // Otherwise, return our string
    return `&contacts=${contactString}`;
  };
  // ***** END CONTACT MANAGEMENT *****

  /**
   * Query the api using the new data
   * @param sendEmail - whether to send an email after saving the link
   */
  const query = async (sendEmail?: boolean) => {
    console.log('Querying api');
    // Let the page know that we're loading
    setQuerying(true);

    // Create an array of promises to execute queries at the same time
    const promises = [];

    // We are either creating a new object or updating one
    promises.push(
      queryAPI(id === 'add' ? 'POST' : 'PUT', {
        apiName: 'django',
        id: id === 'add' ? undefined : id,
        data: {
          ...data,
          status: data.status,
          active_override: (data.active_override && data.status === 'active') || (previousStatus !== 'active' && data.status === 'active'),
          affinity: typeof data.affinity === 'object' ? data.affinity.id : data.affinity,
          site: !UserStore.user || !UserStore.user.backlink.is_superuser ? props.selectedSite.id : data.site,
          owner:
            !UserStore.user || !UserStore.user.backlink.is_superuser
              ? UserStore.user && UserStore.user.backlink.id
              : typeof data.owner === 'object'
              ? data.owner.id
              : data.owner,
        },
      })
    );

    // If we are also sending an email, we need to generate the template
    if (sendEmail) {
      promises.push(
        queryValidationAPI('GET', {
          apiName: 'django',
          route: `templates/${data.template}/translated`,
          queryParams: `url=${data.url}${getContactNames(data.contacts)}`,
        })
      );
    }

    // Wait for any requests to resolve
    const results = await Promise.all(promises);

    // If there were no errors, show a success message and navigate back
    if (!results[0].error && (results.length === 1 || !results[1].error)) {
      NotificationStore.addNotification('success', `Link was successfully ${id === 'add' ? 'added' : 'edited.'}`, `Success`, 2000);

      // Open window with email template (if the email button was clicked)
      if (sendEmail) {
        // Generate list of emails
        const emailArr = [];
        for (const contact of data.contacts) {
          // If this is not a form, grab the email
          if (!contact.is_form) emailArr.push(contact.email);
        }
        // Create email string separated by commas
        const emailStr = emailArr.join(',');

        // Open actual email window with data
        window.open(`mailto:${emailStr}?subject=${encodeURIComponent(results[1].subject)}&body=${encodeURIComponent(results[1].body)}`);
      }

      // Find the site name and id (needed by the import page)
      let siteName = '';
      for (const option of siteOptions) {
        if (option.value === results[0].site) {
          siteName = option.label;
        }
      }

      // Navigate back to previous page (we also return link details, in case we want to use it)
      props.navigateBack(true, {
        id: results[0].id,
        status: results[0].status,
        site: {
          id: results[0].site,
          name: siteName,
        },
        previousURL,
      });
    } else {
      // Finish loading
      setQuerying(false);
    }
  };

  // Set header based on operation being performed
  const headerText = id === 'add' ? 'Add a New Link' : 'Edit Link';

  /**
   * The tab with the link information
   */
  const linkTab = () => {
    return (
      <Fragment>
        {/* URL section */}
        <div className='row'>
          <div className='column'>
            <Input
              className={{ input: `linkModal__input--${urlValid === undefined ? 'unknown' : urlValid ? 'success' : 'error'}` }}
              label='URL'
              value={data.url}
              autoComplete='off'
              onChange={(event) => updateField('url', event.target.value, data, setData)}
              onBlur={() => checkURL()}
            />
          </div>
        </div>

        {/* Status section */}
        <div className='row'>
          <div className='column'>
            <Input label='Date Modified' value={Format.formatDate(new Date(data.modified))} autoComplete='off' required={false} disabled={true} />
          </div>
          <div className='column'>
            <Select
              label='Status'
              value={data.status}
              options={statusOptions}
              disabled={
                UserStore.user &&
                // the user is not a super user
                (!UserStore.user.backlink.is_superuser &&
                  // and the link is not owned by this person
                  UserStore.user.backlink.id !== data.owner &&
                  // and the previous status was active, approved, or closed
                  (previousStatus === 'active' || previousStatus === 'approved' || previousStatus === 'closed'))
              }
              onChange={(value) => updateField('status', value, data, setData)}
            />
          </div>
          <div className='inlineColumn'>
            <div className='row m-0'>
              <Checkbox
                value={data.name}
                // The active override should be true if it was already active overriden and still active || the state was just made active manually
                checked={(data.active_override && data.status === 'active') || (previousStatus !== 'active' && data.status === 'active')}
                disabled={true}
                onChange={() => updateField('active_override', !data.active_override, data, setData)}
              />
              Override
            </div>
          </div>
        </div>

        <hr />

        {/* Contacts section */}
        {generateContacts()}

        <hr />

        {/* Affinity section */}
        <div className='row'>
          <div className='column'>
            <Select
              label='Affinity'
              value={typeof data.affinity === 'object' ? data.affinity.id : data.affinity}
              options={affinityOptions}
              disabled={!data.site}
              onChange={(value) => updateField('affinity', value, data, setData)}
            />
          </div>
          <div className='column'>
            <Select
              label='Template'
              value={data.template}
              options={templateOptions}
              disabled={!data.affinity}
              onChange={(value) => updateField('template', value, data, setData)}
            />
          </div>
        </div>

        <hr />

        {/* Owner section */}
        <div className='row'>
          <div className='column'>
            <Select
              label='Owner'
              value={typeof data.owner === 'object' ? data.owner.id : data.owner}
              options={userOptions}
              disabled={!UserStore.user || !UserStore.user.backlink.is_superuser}
              onChange={(value) => updateField('owner', value, data, setData)}
            />
            {(!UserStore.user || !UserStore.user.backlink.is_superuser) && (
              <span className='linkModal__subNote'>You will be assigned as the owner of this link.</span>
            )}
          </div>
          <div className='column'>
            <Select
              label='Site'
              value={data.site}
              options={siteOptions}
              disabled={!UserStore.user || !UserStore.user.backlink.is_superuser}
              onChange={(value) => updateField('site', value, data, setData)}
            />
            {(!UserStore.user || !UserStore.user.backlink.is_superuser) && (
              <span className='linkModal__subNote'>{props.selectedSite.name} will be assigned to this link.</span>
            )}
          </div>
        </div>
      </Fragment>
    );
  };

  /**
   * The tabs to render in the modal
   */
  const tabs =
    id === 'add'
      ? // Only show the basic tabs if we are adding a link
        [
          {
            title: 'Link',
            body: loading ? undefined : linkTab(),
          },
          {
            title: 'Preview',
            body: loading ? undefined : <PreviewTab templateId={data.template} url={data.url} contacts={data.contacts} />,
            disabled: !data.template,
          },
        ]
      : // Show all tabs if we are editing a link
        [
          {
            title: 'Link',
            body: loading ? undefined : linkTab(),
          },
          {
            title: 'Notes',
            body: loading ? undefined : <NotesTab linkId={id} />,
          },
          {
            title: 'Preview',
            body: loading ? undefined : <PreviewTab templateId={data.template} url={data.url} contacts={data.contacts} />,
            disabled: !data.template,
          },
          {
            title: 'History',
            body: loading ? undefined : <HistoryTab linkId={id} />,
          },
        ];

  /**
   * The save buttons to render in the header
   */
  const LinkModalHeader = () => {
    return (
      <div>
        <Button className='linkModal__headerButton' disabled={disabled} onClick={() => query()}>
          Save
        </Button>
        <Button className='linkModal__headerButton' disabled={disabled} onClick={() => query(true)}>
          <MdEmail />
        </Button>
      </div>
    );
  };

  return (
    <DataModal
      headerText={headerText}
      loading={loading || querying}
      open={open}
      onClose={closeModal}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      className={`${props.className ? props.className : ''}`}
      tabs={tabs}
      customHeader={LinkModalHeader()}
    />
  );
};

/**
 * The modal for link data
 */
export const LinkModal = withRouter(LinkModalBase);

// HELPERS

/**
 * Function to update a property of the data
 * @param field - the field name to update
 * @param value - the value to update the field with
 * @param data - the original data object
 * @param setData - the function to update the data
 */
const updateField = (field: string, value: any, data: any, setData: any) => {
  setData({ ...data, [field]: value });
};
