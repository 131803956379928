import React, { useState } from 'react';
import { Button, Loading, DataTable, SearchBox, CustomFooter, Checkbox, Tabs } from 'lt-components';
import { useAPI } from '../../hooks';
import { Route, RouteComponentProps, withRouter, Switch, Redirect } from 'react-router-dom';
import { Search, Staging } from './components';
import './Prospect.scss';

/**
 * Defines expected props for this component
 */
interface IProps extends RouteComponentProps {
  /**
   * className applied to component
   */
  className?: string;
  /**
   * the site that is currently selected by the user
   */
  selectedSite: any;
}

/**
 * The import page
 */
const ProspectBase = ({ selectedSite }: IProps) => {
  // Fetch data using api hook

  // Figure out which page we're on
  const activePage = determineActive();

  return (
    <div className='full-container'>
      <Tabs
        className={{ container: 'tabMargin' }}
        tabs={[
          {
            path: `/${selectedSite.id}/prospect/search`,
            text: 'Search',
            active: activePage.search,
          },
          {
            path: `/${selectedSite.id}/prospect/staging`,
            text: 'Staging',
            active: activePage.staging,
          },
        ]}
      />
      <Switch>
        {/* Route to view keywords within the campaign */}
        <Route exact path={`/${selectedSite.id}/prospect/search`} render={() => <Search />} />
        {/* Route to view creatives within the campaign */}
        <Route exact path={`/${selectedSite.id}/prospect/staging`} render={() => <Staging />} />
        {/* Redirect any incorrect paths to the first route */}
        <Redirect from='*' to={`/${selectedSite.id}/prospect/search`} />
      </Switch>
    </div>
  );
};

/**
 * Determine which page is currently active
 */
const determineActive = () => {
  const pageArr = window.location.pathname.split('/');
  const pageName = pageArr[pageArr.length - 1];

  return {
    [pageName]: true,
  };
};

// Attach navigation to page
/**
 * The import page
 */
export const Prospect = withRouter(ProspectBase);
