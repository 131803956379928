import React from 'react';
import './SearchResultRow.scss';
import { MdThumbUp, MdThumbDown } from 'react-icons/md';
import { Button } from '@material-ui/core';
import { Loading } from 'lt-components';

/**
 * Defines expected props for this component
 */
interface IProps {
  /**
   * The link of the search result
   */
  link: string;
  /**
   * The heading of the search result
   */
  title: string;
  /**
   * The url of the search result
   */
  visibleLink: string;
  /**
   * The description/snippet of the search result
   */
  snippet: string;
  /**
   * The status of the link in backlink
   */
  status: string;
  /**
   * The selected status of the link
   */
  selection: 'good' | 'bad' | undefined;
  /**
   * Handle link selection
   */
  handleLinkSelection: (selection: 'good' | 'bad' | null, index: number) => void;
  /**
   * className applied to component
   */
  className?: string;
  /**
   * The current index of the data in the search results
   */
  index: number;
}

const renderResult = (link: string, title: string, snippet: string, visibleLink: string, status: string) => {
  return (
    <div className={'searchResult__resultWrapper'}>
      <div className={`searchResult__statusBox-${status}`} />
      <a target='_blank' href={link} className={'searchResult__headline'}>
        {title}
      </a>
      <div className={'searchResult__displayUrl'}>{visibleLink}</div>
      <div className={'searchResult__description'}>
        <span>{snippet}</span>
      </div>
    </div>
  );
};

const renderActionButtons = (
  selection: IProps['selection'],
  index: number,
  handleLinkSelection: (selection: 'good' | 'bad' | null, index: number) => void
) => {
  return (
    <div className={`row m-0 searchResult__actionButtonRow`}>
      <Button
        onClick={() => {
          selection === 'good' ? handleLinkSelection(null, index) : handleLinkSelection('good', index);
        }}
        variant='contained'
        className={`searchResult__actionButton ${selection === 'good' ? 'searchResult__actionButton--thumbUp' : ''}`}
      >
        <MdThumbUp />
      </Button>
      <Button
        onClick={() => {
          selection === 'bad' ? handleLinkSelection(null, index) : handleLinkSelection('bad', index);
        }}
        variant='contained'
        className={`searchResult__actionButton ${selection === 'bad' ? 'searchResult__actionButton--thumbDown' : ''}`}
      >
        <MdThumbDown />
      </Button>
    </div>
  );
};

/**
 * DESCRIPTION_OF_COMPONENT
 */
export const SearchResultRow = ({ link, title, visibleLink, snippet, status, selection, index, handleLinkSelection }: IProps) => {
  return (
    <div className='row m-0 align-center'>
      {renderResult(link, title, snippet, visibleLink, status)}
      {renderActionButtons(selection, index, handleLinkSelection)}
    </div>
  );
};
