import React, { useState, useEffect } from 'react';
import { OperationsToolbar } from '../../components';
import { Button, DataTable, CustomHeadRender, DatePicker, CustomFilterList, ConfirmationModal } from 'lt-components';
import { useAPI, useFilters } from '../../hooks';
import { ImportModal, ImportDrillDown } from './components';
import { Route, RouteComponentProps, withRouter, Switch } from 'react-router-dom';
import { Filter, Format } from '../../utilities';
import { NotificationStore } from '../../stores';
import { MdClear, MdRemoveRedEye, MdDone } from 'react-icons/md';
import './Imports.scss';

/**
 * Defines expected props for this component
 */
interface IProps extends RouteComponentProps {
  /**
   * the site that is currently selected by the user
   */
  selectedSite: any;
}

/**
 * The imports page
 */
const ImportsBase = (props: IProps) => {
  // Fetch data using api hook
  const { loading, data, queryAPI, refreshData } = useAPI('link-imports', {
    apiName: 'django',
    queryParams: 'hide_archived=true',
  });
  // Manages the state of the filters and the table
  const {
    filters,
    filterStartDate,
    filterEndDate,
    appliedStartDate,
    appliedEndDate,
    setFilterDate,
    setAppliedDate,
    setFilters,
    sort,
    setSort,
  } = useFilters({
    column: 'created',
    direction: 'desc',
  });
  // Track formatted version of data
  const [formattedData, setFormattedData] = useState<any[]>([]);
  // Track filtered version of data
  const [filteredData, setFilteredData] = useState<any[]>([]);
  // Track whether delete modal is open
  const [deleteOpen, setDeleteOpen] = useState(false);
  // Track the entry selected for deletion
  const [deleteSelection, setDeleteSelection] = useState({
    id: undefined,
    name: undefined,
  });

  // ***** START DATA FORMATTING *****
  /**
   * Format the data as soon as we receive it
   */
  useEffect(() => {
    // Make sure we're done loading
    if (!loading) {
      const formatted: any[] = [];

      for (const row of data) {
        formatted.push({
          ...row,
          is_direct: row.is_direct ? 'yes' : 'no',
          // Format created as a date object
          created: new Date(row.created),
        });
      }

      setFormattedData(formatted);
    }
  }, [loading, data]);

  /**
   * Filter the data whenever a filter option changes
   */
  useEffect(() => {
    console.log(appliedStartDate, appliedEndDate);
    // Filter out anything that doesn't match the applied filters
    const filtered = formattedData.filter((row: any) => {
      if (filters.name && !row.name.toLowerCase().includes(filters.name.value.toLowerCase())) return false;
      if (filters.status && row.status !== filters.status.value) return false;
      if (filters.is_direct && row.is_direct !== filters.is_direct.value) return false;
      if ((appliedStartDate && appliedStartDate > row.created) || (appliedEndDate && appliedEndDate <= row.created)) return false;
      return true;
    });

    setFilteredData(filtered);
  }, [filters, appliedStartDate, appliedEndDate, formattedData]);
  // ***** END DATA FORMATTING *****

  /**
   * Clear filters on table
   */
  const clearFilters = () => {
    setFilterDate(null, null);
    setAppliedDate(null, null);
    setFilters({});
  };

  /**
   * Navigate back to imports page and refresh data
   * @param refresh - whether to refresh the table (should be true when data was edited)
   */
  const navigateBack = (refresh?: boolean, id?: number) => {
    // If we are asked to refresh, refresh the table
    if (refresh) refreshData();

    // Navigate back to table
    props.history.push(`/${props.selectedSite.id}/imports`);

    // If we just created a new import, navigate there
    if (id) props.history.push(`/${props.selectedSite.id}/imports/${id}`);
  };

  /**
   * Navigate to the drillDown page
   */
  const navigateToDrillDown = (id: number) => {
    props.history.push(`/${props.selectedSite.id}/imports/${id}`);
  };

  /**
   * Delete the selected item
   */
  const deleteItem = async () => {
    // Determine which item to delete based on which item was selected
    console.log('Attempting to delete', deleteSelection);

    const result = await queryAPI('DELETE', {
      apiName: 'django',
      route: `link-imports/${deleteSelection.id}`,
    });

    // Check the response for errors
    if (result.error) {
      NotificationStore.addNotification('error', `There was a problem deleting ${deleteSelection.name}`, 'Error');
      return;
    }

    // If there are no errors, show a success messages
    NotificationStore.addNotification('success', `Successfully deleted ${deleteSelection.name}`, `Success`, 2000);

    // Refresh the table after the deletion
    refreshData();
  };

  /**
   * Column options used to render table columns
   */
  const columns = [
    {
      name: 'created',
      label: 'Date',
      rerender: filters,
      options: {
        sort: true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender key={columnMeta.index} columnMeta={columnMeta} handleToggleColumn={handleToggleColumn} fixed />
        ),
      },
    },
    {
      name: 'name',
      label: 'Name',
      rerender: filters,
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixed
            filter={'Search'}
          />
        ),
      },
    },
    {
      name: 'is_direct',
      label: 'Assigned',
      rerender: filters,
      options: {
        filter: false,
        sort: true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixed
            filter={'Radio'}
            filterValues={[
              {
                value: 'yes',
                label: 'Yes',
              },
              {
                value: 'no',
                label: 'No',
              },
            ]}
          />
        ),
      },
    },
    {
      name: 'status',
      label: 'Status',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixed
            filter={'Select'}
            filterValues={[
              {
                value: 'success',
                label: 'Success',
              },
              {
                value: 'error',
                label: 'Error',
              },
              {
                value: 'importing',
                label: 'Importing',
              },
              {
                value: 'pending',
                label: 'Pending',
              },
            ]}
          />
        ),
      },
    },
    {
      name: 'id',
      label: 'id',
      options: {
        display: false,
      },
    },
  ];

  /**
   * Options for table
   */
  const options = {
    selectableRows: 'none' as 'none',
    rowsPerPageOptions: [10, 50, 100],
    rowsPerPage: 10,
    responsive: 'scrollMaxHeight',
    filter: false,
    search: false,
    viewColumns: false,
    download: false,
    customToolbar: () => (
      <>
        {/* If we have filters applied, show the clear button */}
        {(!Format.isObjectEmpty(filters) || appliedStartDate || appliedEndDate) && (
          <Button className='clearFiltersButton' onClick={() => clearFilters()}>
            Clear Filters
          </Button>
        )}
        <DatePicker
          startDate={filterStartDate}
          endDate={filterEndDate}
          appliedEndDate={appliedEndDate}
          appliedStartDate={appliedStartDate}
          setAppliedDatesChange={setAppliedDate}
          applyDatesFilter={Filter.applyDatesFilter}
          appliedAllowNullInputs
          showClearDates
          onDatesChange={setFilterDate}
        />
        <CustomFilterList filters={filters} setFilter={setFilters} clearFilter={Filter.clearFilter} />
      </>
    ),
    customRowRender: (data: any, dataIndex: number, rowIndex: number) => {
      return (
        <tr className='MuiTableRow-root' key={rowIndex}>
          <td className='MuiTableCell-root operationsToolbar__wrapper'>
            <span>{Format.formatDateTime(data[0])}</span>
            <OperationsToolbar
              buttons={[
                // Button to view and drillDown into an import
                {
                  onClick: () => navigateToDrillDown(data[4]),
                  icon: <MdRemoveRedEye />,
                  color: 'green',
                },
                // Button to delete imports
                {
                  onClick: () => {
                    setDeleteSelection({
                      id: data[4],
                      name: data[1],
                    });
                    setDeleteOpen(true);
                  },
                  icon: <MdClear />,
                  color: 'red',
                },
              ]}
            />
          </td>
          <td className='MuiTableCell-root'>
            <span>{data[1]}</span>
          </td>
          <td className='MuiTableCell-root'>
            <span>{data[2] === 'yes' && <MdDone className='imports__assignedIcon' />}</span>
          </td>
          <td className='MuiTableCell-root'>
            <span className={`imports__${data[3]}`}>
              <strong>{Format.titleCase(data[3])}</strong>
            </span>
          </td>
        </tr>
      );
    },
    onTableChange: (action: any, tableState: any) => {
      console.log(tableState);
      // Take a specific action based on change
      switch (action) {
        // Column has been sorted
        case 'sort':
          // Loop through columns and determine which one is sorted
          for (let i = 0; i < tableState.columns.length; i++) {
            if (tableState.columns[i].sortDirection !== 'none') {
              const column = columns[i].name;
              const direction = tableState.columns[i].sortDirection;
              setSort({ column, direction });
              break;
            }
          }
          break;
      }
    },
  };

  // Apply sorting to table based on state
  if (sort.column) {
    const index = columns.findIndex((item) => item.name === sort.column);
    // @ts-ignore
    if (columns[index] && columns[index].options) columns[index].options.sortDirection = sort.direction;
  }

  return (
    <Switch>
      {/* Normal route (show all spreadsheets) */}
      <Route
        path={`/${props.selectedSite.id}/imports(|/new)`}
        exact
        render={() => {
          return (
            <div className='full-container'>
              <div className='row m-0 align-center'>
                <h1>Imports</h1>
                <Button className='m-l-a' onClick={() => props.history.push(`/${props.selectedSite.id}/imports/new`)}>
                  Import Spreadsheet
                </Button>
              </div>

              {/* Show the table once the data is ready */}
              <DataTable loading={loading} options={options} columns={columns} data={filteredData} />

              {/* Modal route (import spreadsheet) */}
              <Route
                path={`/${props.selectedSite.id}/imports/new`}
                render={() => {
                  return <ImportModal navigateBack={navigateBack} />;
                }}
              />

              {/* Modal that's shown for deletions */}
              <ConfirmationModal
                loading={loading}
                open={deleteOpen}
                message={`Are you sure you want to delete ${deleteSelection.name}?`}
                onClose={() => setDeleteOpen(false)}
                execute={() => deleteItem()}
              />
            </div>
          );
        }}
      />

      {/* DrillDown route (view specific spreadsheet) */}
      <Route
        path={`/${props.selectedSite.id}/imports/:id`}
        render={() => {
          return <ImportDrillDown navigateBack={navigateBack} selectedSite={props.selectedSite} />;
        }}
      />
    </Switch>
  );
};

// Attach navigation to page
/**
 * The imports page
 */
export const Imports = withRouter(ImportsBase);
