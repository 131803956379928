import React, { useState } from 'react';
import './Search.scss';
import { SearchBox, Loading } from 'lt-components';
import { SearchResultRow, Pagination } from './components';
import { config } from '../../../../config';
import { UserStore } from '../../../../stores';

/**
 * Defines expected props for this component
 */
interface IProps {
  /**
   * className applied to component
   */
  className?: string;
}

interface ISearchResult {
  /**
   * The link of the google search result
   */
  link: string;
  /**
   * The description of the link in the google results
   */
  snippet: string;
  /**
   * The title tag of the url
   */
  title: string;
  /**
   * The visible link of the google search
   */
  visible_link: string;
  /**
   * The status of the search result that link fox processed
   */
  status: string;
  /**
   * The top level domain name of the url that we scraped
   */
  tld: string;
  /**
   * The domain of the item
   */
  domain: string;
  /**
   * The path of the url
   */
  path: string;
  /**
   * Whether the item was selected
   */
  selection?: 'good' | 'bad';
}

/**
 * DESCRIPTION_OF_COMPONENT
 */
export const Search = (props: IProps) => {
  const [prospectLoading, setProspectLoading] = useState(false);
  const [placeholderText, setPlaceholderText] = useState('Please provide a search query');
  const [searchResults, setSearchResults] = useState<ISearchResult[][]>([]);

  // Set the start page and pagination
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([1]);
  // The query currently in the box
  const [query, setQuery] = useState('');
  // The query that we want to use for searching, avoiding the scenario
  // where the user changes pages and the query text
  const [searchQuery, setSearchQuery] = useState('');

  const handleQueryChange = (queryText: string) => {
    // Set the text of the search box query
    setQuery(queryText);
  };

  const handleQuerySubmit = async () => {
    // Set the new pagination on a query
    setPagination([1]);
    setPage(1);
    setSearchQuery(query);
    // Reset the results
    setSearchResults([]);
    prospect(1, query);
  };

  const handlePaginationUpdate = async (pageNumber: number) => {
    const newPagination = [...pagination];
    setPage(pageNumber);
    if (pageNumber > pagination[pagination.length - 1]) {
      newPagination.push(pageNumber);
      await prospect(pageNumber);
    }
    setPagination(newPagination);
  };

  const prospect = async (pageNumber?: number, searchQuery?: string) => {
    setProspectLoading(true);
    try {
      // Send one time auth code to server to receive personal details back
      const result = await fetch(`${config.api.prospect}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: searchQuery ? searchQuery : query,
          page: pageNumber ? pageNumber : page,
          token: UserStore.user ? UserStore.user.backlink.token : '',
        }),
      });
      // Parse the streamable body to recieve the results from the api
      const data = await result.json();
      const searchResultQuery = data.searchResults;
      console.log('The search results query: ', searchResultQuery);
      if (searchResultQuery === undefined || searchResultQuery.length === 0) {
        if ((pageNumber && pageNumber > 1) || page > 1) {
          // You need to intialize the array before pushing it into the other array
          const emptyArray: ISearchResult[] = [];

          // Add an empty array to the new search results so that
          // this can be reflected in the UI and handled by the pagination
          const newSearchResults = [...searchResults, emptyArray];
          setSearchResults(newSearchResults);
        }

        // Set the placeholder text inside of the search results area
        setPlaceholderText(
          `The query returned no results, please try another search${
            (pageNumber && pageNumber > 1) || page > 1 ? ' or try going back to the previous pages.' : '.'
          }`
        );
        setProspectLoading(false);
        return;
      }
      searchResultQuery.map((result: ISearchResult) => {
        return { ...result, selected: undefined, loading: false };
      });
      const newSearchResults: ISearchResult[][] = [...searchResults, searchResultQuery];
      console.log('New search results query: ', newSearchResults);
      setSearchResults(newSearchResults);
    } catch (err) {
      console.log(err);
    }
    setProspectLoading(false);
  };

  /**
   * Function used to handle the selection of the links in backlink
   */
  const handleLinkSelection = async (selection: 'good' | 'bad' | null, index: number) => {
    const newSearchResults = [...searchResults];

    try {
      const result = await fetch(`${config.api.staging}/links?url=${newSearchResults[page - 1][index].link}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const parsedResult = await result.json();

      // If the item comes back as not being there create it in the staging table
      if (!Object.keys(parsedResult).length) {
        // Create a new object with the data that we want to post into the database
        const postData = { ...newSearchResults[page - 1][index] };
        delete postData.link;

        console.log('The data to post: ', postData);

        await fetch(`${config.api.staging}/links/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            url: newSearchResults[page - 1][index].link,
            owner: UserStore.user && UserStore.user.email,
            ...postData,
          }),
        });
      }

      if (selection === null) {
        // Delete the item from  staging database
        await fetch(`${config.api.staging}/links/`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            url: newSearchResults[page - 1][index].link,
          }),
        });
        delete newSearchResults[page - 1][index].selection;
      } else if (selection === 'good') {
        // Update the staging
        await fetch(`${config.api.staging}/links/`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            url: newSearchResults[page - 1][index].link,
            acceptable: 1,
          }),
        });
        newSearchResults[page - 1][index].selection = selection;
      } else if (selection === 'bad') {
        // Delete the item from  staging database
        await fetch(`${config.api.staging}/links/`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            url: newSearchResults[page - 1][index].link,
            acceptable: 0,
          }),
        });
        newSearchResults[page - 1][index].selection = selection;
      }

      setSearchResults(newSearchResults);
    } catch (err) {
      console.log('There was an error updating the selected link in the machine learning or staging database: ', err);
    }
  };

  return (
    <div>
      <div className='search__searchBarRow row'>
        <SearchBox
          className={{ container: 'search__searchBarContainer', input: 'search__searchBarInput' }}
          value={query}
          placeholder='Search Google'
          onChange={(event: any) => handleQueryChange(event.target.value)}
          onSubmit={handleQuerySubmit}
        />
      </div>
      <div className='column m-0 search'>
        {!prospectLoading ? (
          <React.Fragment>
            <div className='search__searchResults column m-0'>
              {searchResults.length === 0 && (
                <div className='column m-0'>
                  <p>{placeholderText}</p>
                </div>
              )}
              {searchResults.map((results: ISearchResult[], pageIndex: number) => {
                if (page === pageIndex + 1) {
                  return (
                    <React.Fragment key={pageIndex}>
                      {searchResults[pageIndex] === undefined ||
                        (searchResults[pageIndex].length === 0 && (
                          <div className='column m-0'>
                            <p>{placeholderText}</p>
                          </div>
                        ))}
                      {results.map((result: ISearchResult, index: number) => (
                        <SearchResultRow
                          key={index}
                          link={result.link}
                          title={result.title}
                          snippet={result.snippet}
                          visibleLink={result.visible_link}
                          status={result.status}
                          selection={result.selection}
                          handleLinkSelection={handleLinkSelection}
                          index={index}
                        />
                      ))}
                    </React.Fragment>
                  );
                }
              })}
            </div>
            <Pagination
              nextDisabled={searchResults.length === 0 || searchResults[page - 1].length === 0}
              pagination={pagination}
              handlePaginationUpdate={handlePaginationUpdate}
              currentPage={page}
            />
          </React.Fragment>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};
