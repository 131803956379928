import React from 'react';
import './OperationsToolbar.scss';

/**
 * Defines expected props for this component
 */
interface IProps {
  /**
   * The list of operation buttons to render
   */
  buttons: IButton[];
  /**
   * Whether to include a slight offset for a status bar
   */
  statusBarOffset?: boolean;
}

/**
 * Defines expected properties of a button
 */
interface IButton {
  onClick: (...args: any[]) => any;
  icon: React.ReactNode;
  color: 'red' | 'green' | 'yellow';
}

/**
 * Helper function that renders buttons
 */
const generateButtons = (buttons: IButton[]) => {
  return buttons.map((button: IButton, index: number) => {
    return (
      <button
        key={index}
        className={`operationsToolbar__button ${(button.color && `operationsToolbar__button--${button.color}`) || ''}`}
        onClick={() => button.onClick()}
      >
        {button.icon}
      </button>
    );
  });
};

/**
 * Provides the hover button options for table rows
 */
export const OperationsToolbar = ({ buttons, statusBarOffset }: IProps) => {
  return <div className={`operationsToolbar ${statusBarOffset ? 'operationsToolbar--statusOffset' : ''}`}>{generateButtons(buttons)}</div>;
};
