import React from 'react';
import './Dash.scss';

/**
 * The default dashboard page
 */
export const Dash = () => {
  return (
    <div className='full-container'>
      <p>Please select a site to continue</p>
    </div>
  );
};
