import React, { useState, memo } from 'react';
import { Accordion as LTAccordion, TextArea, Button, Loading } from 'lt-components';
import { useAPI } from '../../../../../../hooks';
import { UserStore } from '../../../../../../stores';
import { Format } from '../../../../../../utilities';
import './NotesTab.scss';

/**
 * Defines expected props for this component
 */
interface IProps {
  /**
   * the id of the link to display notes for
   */
  linkId: string;
}

/**
 * Defines the properties that should be attached to notes
 */
interface INote {
  /**
   * The body of the note
   */
  body: string;
  /**
   * The date the note was created
   */
  created: string;
}

// Memoize accordion component
const Accordion = memo(LTAccordion);

/**
 * The notes tab of the links modal
 */
export const NotesTab = ({ linkId }: IProps) => {
  // Retrieve a list of users from the django backlink api
  const { loading, data: notes, setData: setNotes, queryAPI } = useAPI('notes', {
    apiName: 'django',
    queryParams: `link=${linkId}&fields=body,created`,
    emptyObj: [],
    noFetch: linkId === 'add',
  });
  // Track state of new note text
  const [newNote, setNewNote] = useState('');

  /**
   * Create a new note on the link
   */
  const createNote = async () => {
    const result = await queryAPI('POST', {
      apiName: 'django',
      data: {
        body: newNote,
        link: linkId,
        user: UserStore.user && UserStore.user.backlink.id,
      },
    });

    // If the note was successfully saved, update the notes in the UI
    const newNotes = [
      ...notes,
      {
        body: newNote,
        created: Format.formatDate(new Date()),
      },
    ];
    setNotes(newNotes);
    setNewNote('');
  };

  /**
   * Generate list of notes for the UI
   * @param notes - the notes to render
   */
  const generateNotes = (notes: INote[]) => {
    return notes.map((note: any) => {
      return {
        // The top part of each accordion item
        heading: (
          <div className='notesTab__heading'>
            <div className='notesTab__headingText'>{note.body}</div>
            <div className='notesTab__date'>{Format.formatDate(new Date(note.created))}</div>
          </div>
        ),
        // The hidden panel of each accordion item
        body: <p className='notesTab__body'>{note.body}</p>,
      };
    });
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <p className='notesTab__numNotes'>Notes ({notes.length})</p>
          {/* Only render accordion if we have notes */}
          {!!notes.length && (
            <>
              <hr />
              <div className='notesTab__accordionContainer'>
                <Accordion
                  className={{ heading: 'notesTab__accordionHeading' }}
                  allowMultipleExpanded
                  allowZeroExpanded
                  items={generateNotes(notes)}
                />
              </div>
              <hr />
            </>
          )}

          <div className='notesTab__bottom'>
            <p className='notesTab__addText'>Add a New Note</p>
            <TextArea className={{ container: 'notesTab__textArea' }} rows={5} value={newNote} onChange={(event) => setNewNote(event.target.value)} />
            <div className='row m-0'>
              <Button disabled={!newNote} className='dataModal__button' onClick={() => createNote()}>
                Save Note
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};
