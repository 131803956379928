import React from 'react';
import { Loading, TextArea, Input } from 'lt-components';
import { useAPI } from '../../../../../../hooks';
import './PreviewTab.scss';

/**
 * Defines expected props for this component
 */
interface IProps {
  /**
   * The id of the template being used
   */
  templateId: number;
  /**
   * The list of contacts selected on the links tab
   */
  contacts?: {
    name: string;
  }[];
  /**
   * The url being contacted
   */
  url?: string;
}

/**
 * Extract the contact names (used to generate dynamic template)
 */
const getContactNames = (contacts: IProps['contacts']) => {
  // If there are no contacts, return an empty string
  if (!contacts) return '';
  // Otherwise, extract any names from the contacts

  // Track whether we found any names
  let namesExist = false;
  // Track list of names used for query
  let contactString = '';

  // Loop over each contact
  for (const contact of contacts) {
    // If we find a name, append it to our contact string
    if (contact.name) {
      namesExist = true;
      contactString += `${contactString.length ? ',' : ''}${contact.name}`;
    }
  }

  // If we didn't find any names, return an empty string
  if (!namesExist) return '';
  // Otherwise, return our string
  return `&contacts=${contactString}`;
};

/**
 * The preview template tab of the links modal
 */
export const PreviewTab = ({ templateId, contacts, url }: IProps) => {
  // Retrieve a preview of the template from the API
  const { loading, data: template } = useAPI(`templates/${templateId}/translated`, {
    apiName: 'django',
    queryParams: `url=${url}${getContactNames(contacts)}`,
    emptyObj: {},
  });

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <Input label='Title' disabled={true} value={template.title} />
          <Input label='subject' disabled={true} value={template.subject} />
          <TextArea label='Message' disabled={true} rows={16} value={template.body} />
        </>
      )}
    </>
  );
};
