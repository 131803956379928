import { NotificationStore } from '.';
import { createContext } from 'react';
import { observable, decorate } from 'mobx';
import { getTrollUser } from '../troll/troll';

/**
 * Requirements for the user object
 */
export interface IUser {
  /**
   * first name of user
   */
  firstName: string;
  /**
   * last name of user
   */
  lastName: string;
  /**
   * user's email
   */
  email: string;
  /**
   * URL of user's picture
   */
  picture?: string;
  /**
   * encrypted token used for authentication
   */
  token: string;
  /**
   * the roles associated with the user
   */
  roles: string[];
  /**
   * whether the user is an admin
   */
  isAdmin: boolean;
  /**
   * backlink specific details used to support legacy system
   */
  backlink: {
    /**
     * backlink userId
     */
    id: number;
    /**
     * backlink authentication token
     */
    token: string;
    /**
     * the backlink permissions for this user (what groups they belong to)
     */
    siteRoles: string[];
    /**
     * the old backlink groups that are initially returned
     */
    groups?: {
      name: string;
      permissions: string[];
    }[];
    /**
     * whether the user is active in backlink (has access)
     */
    is_active: boolean;
    /**
     * whether the user has super user status
     */
    is_superuser: boolean;
  };
  /**
   * whether to troll the user (TODO: remove)
   */
  troll?: boolean;
}

/**
 * The user store handles user related functions
 */
class Store {
  user: IUser | undefined;

  /**
   * Set the user
   * @param user - the updated user object
   * @param setStorage - whether to store the user in local storage (during login)
   */
  signIn(user: IUser, setStorage?: boolean) {
    console.log('Signing in', user);

    // Don't allow inactive users to sign in
    if (!user.backlink.is_active) {
      NotificationStore.addNotification('error', `You are currently inactive`, 'Error Signing In');
      return;
    }

    // Delete any instance of group permissions (nested in backlink groups)
    if (user.backlink.groups) {
      user.backlink.siteRoles = [];
      for (const role of user.backlink.groups) {
        user.backlink.siteRoles.push(role.name);
      }
      delete user.backlink.groups;
    }

    // TODO: remove Troll
    this.user = user.firstName === 'Rob' && user.lastName === 'Rule' ? { ...user, ...getTrollUser(), troll: true } : user;

    // If requested, set the local storage
    if (setStorage) {
      // Update browser storage with user details and token
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  /**
   * Sign the user out
   */
  signOut() {
    console.log('Signing out');
    this.user = undefined;

    // Remove browser token
    localStorage.removeItem('user');
  }
}

/**
 * Decorate any observables
 */
decorate(Store, {
  user: observable,
});

/**
 * Returns current instance of user store
 * (or creates a new instance if it does not exist).
 */

/**
 * The class based version of UserStore (used outside of components to call functions)
 */
export const UserStore = new Store();

/**
 * The context based version of UserStore (used inside of components to track changes)
 */
export const UserContext = createContext(UserStore);
