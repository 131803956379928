/**
 * Provides functions relating to formatting
 */
export const Format = {
  /**
   * Format the date nicely (Jan 18, 2019)
   * @param date - date object
   * @return
   * @param {Date} dateStr - the text version of the date
   */
  formatDate: (date: Date) => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${monthNames[monthIndex]} ${day}, ${year}`;
  },

  /**
   * Format the date nicely (Jan 18, 2019)
   * @param date - date object
   * @return
   * @param {Date} timeStr - the text version of the time
   */
  formatTime: (date: Date) => {
    let hours = date.getHours();
    let minutes: number | string = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  },

  /**
   * Format the date nicely (Jan 18, 2019)
   * @param date - date object
   * @return
   * @param {Date} dateTimeStr - the text version of the date time
   */
  formatDateTime: (date: Date) => {
    return `${Format.formatDate(date)} ${Format.formatTime(date)}`;
  },

  /**
   * Format string as title case
   */
  titleCase: (str: string) => {
    return str.slice(0, 1).toUpperCase() + str.slice(1);
  },

  /**
   * Check whether an object is empty
   */
  isObjectEmpty: (obj: { [key: string]: any }) => {
    for (const key in obj) {
      // If this runs, there was a key and it's not empty
      if (key) return false;
    }
    // If there were no keys, return true
    return true;
  },
};
